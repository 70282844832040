import { formatNumberOnly } from "../../../utils/formatNumber";
import EmandateHistory from "../../../components/specific/admin_side_loan/emandateHistory/EmandateHistory";
import { useGetMandates, useStatistics } from "../../../api/getHooks";
import { useProfileData } from "../../../hooks/useProfileData";
import create_mandate_img from "../../../assets/dashboard_img_card.png";
import CustomButton from "../../../components/common/CustomButton";

import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import moment from "moment";
import { Select } from "../../../components/common/form_inputs/Select";
import { useEffect, useMemo } from "react";
import { useGetStatistics } from "../../../api/admin_mutation";
import { decryptData } from "../../../utils/cryptData";
import ButtonConfig from "../../../components/common/ButtonConfig";
import { Button } from "antd";
import { DashboardCustomizeIcon } from "../../../utils/svg_icons";
import DashboardCreateButton from "../../../components/specific/admin_side_loan/dashboardTask/DashboardCreateButton";

export default function EmandateDashboard() {
  // const { data: statData } = useStatistics({
  //   start_date: moment().startOf("today").format("YYYY/MM/DD"),
  //   end_date: moment().endOf("today").format("YYYY/MM/DD"),
  // });

  const { isAdmin } = useProfileData();

  const { mutate, data: statData, isPending } = useGetStatistics(isAdmin);

  useEffect(() => {
    mutate({
      start_date: moment().startOf("today").format("YYYY/MM/DD"),
      end_date: moment().endOf("today").format("YYYY/MM/DD"),
    });
  }, [isAdmin, mutate]);

  const statisticData = useMemo(() => {
    return statData?.data?.data;
  }, [statData]);

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const createMandate = () => {
    openDrawer("MANDATE_TYPE");
    updateDrawerTitle("New Mandate");
  };

  // Set the locale to start the week on Monday
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    const startDate = moment().startOf(value).format("YYYY/MM/DD");
    const endDate = moment().endOf(value).format("YYYY/MM/DD");

    mutate({
      start_date: startDate,
      end_date: endDate,
    });
  };

  const statisticsItems = [
    {
      name: "Mandate Request count",
      value: decryptData(statisticData?.total_mandates) ?? 0,
    },
    {
      name: isAdmin ? "Active Mandate count" : "Authorized mandate count",
      value: isAdmin
        ? decryptData(statisticData?.active_account) ?? 0
        : decryptData(statisticData?.total_authorized) ?? 0,
    },
    isAdmin && {
      name: "Number of Business",
      value: decryptData(statisticData?.number_of_business) ?? 0,
    },
    {
      name: "Total Credit approved",
      value: formatNumberOnly(decryptData(statisticData?.total_amount) ?? 0),
    },
    {
      name: "Total Repayment",
      value: formatNumberOnly(decryptData(statisticData?.total_paid) ?? 0),
    },
    isAdmin && {
      name: "Number of Customers",
      value: decryptData(statisticData?.numberOfCustomer) ?? 0,
    },
  ]?.filter(Boolean);

  const onboardBusiness = () => {
    openDrawer("ONBOARD_COMPANY");
  };

  return (
    <>
      <div className="">
        <div className="flex items-center gap-x-5 text-sm mb-3">
          Filter By:
          <Select
            size="small"
            defaultValue="all_time"
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "all_time", label: "All time" },
              { value: "today", label: "Today" },
              { value: "week", label: "This week" },
              { value: "month", label: "This month" },
              { value: "year", label: "This year" },
            ]}
          />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mx-2">
          <div
            className={`${
              isAdmin
                ? "col-span-3 grid md:grid-cols-2 lg:grid-cols-3"
                : "col-span-2 grid md:grid-cols-2"
            } grid-cols-1 gap-5 mx-2`}
          >
            {statisticsItems?.map((item, index) => (
              <div className="" key={decryptData(item?.name) + "___" + index}>
                <div className="h-24 flex flex-col justify-between p-5 rounded bg-zinc-50 shadow">
                  <div className="flex justify-between">
                    <p className="font-[500] text-[14px]">
                      {decryptData(item?.name)}
                    </p>
                  </div>
                  <p className="font-[700] text-[17px] text-[#282828]">
                    {decryptData(item?.value)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={isAdmin ? "col-span-3" : ""}>
            {isAdmin ? (
              <div className="flex justify-center mb-4 mt-4">
                <CustomButton
                  className={""}
                  type="submit"
                  onClick={onboardBusiness}
                >
                  Onboard Business
                </CustomButton>
              </div>
            ) : (
              <div className="relative">
                <img
                  src={create_mandate_img}
                  alt="create mandate"
                  className="w-full object-cover"
                />
                <DashboardCreateButton />
              </div>
            )}
          </div>
        </div>

        {/* <NewMandate handleClick={handleClick} /> */}

        <EmandateHistory isAdmin={isAdmin} />
      </div>
    </>
  );
}
