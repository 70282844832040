import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Input, InputNumber } from "../../../common/form_inputs/Input";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const NewUserForm = () => {
  const { updateData, updateCurrentPage } = useCustomerLoanApplication();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
    control,
  } = useForm();

  const onSubmit = (data) => {
    // the loan application link will be created from here and sent to user email
    updateData({ new_user: data});

    updateCurrentPage('COMPLETE_KYC', { new_user: data});


  };

  const onChangeValue = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName);
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    // Allow digits and a single decimal point
    if (
      !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
      (event.key === "." && inputValue.includes(".")) // Block multiple periods
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <main className="w-full max-w-lg flex flex-col gap-y-7 my-5">
        <h3 className="font-[500] text-[16px]">
          Seems You are new, Let’s Know You!
        </h3>

          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="nin" className="font-[400] text-[14px]">
                NIN
              </label>
              <Controller
                name="nin"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/[^\d]/g, "")}
                    defaultValue={getValues("nin")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.nin ? "error" : ""
                    }`}
                    status={errors.nin ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("nin");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.nin && errors.nin.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="first_name" className="font-[400] text-[14px]">
                First Name*
              </label>
              <Input
                name="first_name"
                defaultValue={getValues("first_name")}
                placeholder="Enter your First Name"
                size={"large"}
                className={`text-[14px] ${errors.first_name ? "error" : ""}`}
                status={errors.first_name ? "error" : ""}
                {...register("first_name", {
                  required: "First name is required",
                })}
                onChange={(e) => onChangeValue(e, "first_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.first_name && errors.first_name.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="last_name" className="font-[400] text-[14px]">
                Last Name*
              </label>
              <Input
                name="last_name"
                defaultValue={getValues("last_name")}
                placeholder="Enter your Last Name"
                size={"large"}
                className={`text-[14px] ${errors.last_name ? "error" : ""}`}
                status={errors.last_name ? "error" : ""}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                onChange={(e) => onChangeValue(e, "last_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.last_name && errors.last_name.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="phone" className="font-[400] text-[14px]">
                Phone Number*
              </label>
              <Input
                name="phone"
                defaultValue={getValues("phone")}
                type="number"
                placeholder="Enter Your Phone number"
                size={"large"}
                className={`text-[14px] ${errors.phone ? "error" : ""}`}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                onChange={(e) => onChangeValue(e, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="font-[400] text-[14px]">
                E-mail*
              </label>
              <Input
                name="email"
                defaultValue={getValues("email")}
                type="email"
                placeholder="Enter your Email"
                size={"large"}
                className={`text-[14px] ${errors.email ? "error" : ""}`}
                status={errors.email ? "error" : ""}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => onChangeValue(e, "email")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.email && errors.email.message}
              </small>
            </div>

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                // loading={isLoading}
              >
                Continue
              </CustomButton>
            </div>
          </form>
      </main>
    </>
  );
};

export default NewUserForm;
