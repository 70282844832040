
import { create } from "zustand";
export const useCustomerLoanApplication = create((set)=>{
    return {

            currentPage: "LOAN_REQUEST",
            data: {},
            kyc: {
                currentStep: 0,
            },

            changeStep: (step)=>set((state)=>({kyc: {...state.kyc, currentStep: step}})),

            updateData: (data)=>set((state)=>( { data: {...state.data, ...data} })),
    
            updateCurrentPage: (page, data)=>set((state)=>({currentPage: page, data: {...state.data, ...data}})),
        }
})