import { ConfigProvider, Steps } from "antd";

const KycStepper = ({currentStep}) => {
  return (
    <>
      <ConfigProvider
      theme={{
        token: {
            colorPrimary: '#733534',
            lineWidth: 3,
        },
        components: {
            Steps: {
                vertical: {
                    size: 'large',
                },
                titleLineHeight: 50
            },
        }
      }}
      >
        <div className="flex flex-col h-screen p-4">
        <Steps
          direction="vertical"
          current={currentStep}
          className="flex-grow"
          items={[
            { status: "finish", key: "step-1" },
            { status: "process", key: "step-2" },
            { status: "wait", key: "step-3" },
          ]}
        />
      </div>
      </ConfigProvider>
    </>
  );
};

export default KycStepper;
