import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { Controller, useForm } from "react-hook-form";
import { InputNumber } from "../../../common/form_inputs/Input";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";



const LetKnowYou = () => {
  const {
    data: { values },
  } = useDrawer();

   const { updateCurrentPage } = useCustomerLoanApplication();

  const {
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      nin: values?.nin,
    },
    mode: "onBlur",
    criteriaMode: "all",
  });

  const onSubmit = (data) => {

    //if this nin is found with us, then we can proceed to the next page but if not we will take theperson to NewUserForm
    
    updateCurrentPage('NEW_USER_FORM', data);

    console.log(data);
    // updateCurrentPage('COMPLETE_KYC', data);
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    // Allow digits and a single decimal point
    if (
      !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
      (event.key === "." && inputValue.includes(".")) // Block multiple periods
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Let’s know you!</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
           
            <div className="mb-2">
              <label htmlFor="nin" className="font-[400] text-[14px]">
                Enter NIN
              </label>
              <Controller
                name="nin"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/[^\d]/g, "")}
                    defaultValue={getValues("nin")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.nin ? "error" : ""
                    }`}
                    status={errors.nin ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("nin");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.nin && errors.nin.message}
              </small>
            </div>

           
            <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default LetKnowYou;