import { Input, Result } from "antd";
import { useEffect, useState, useMemo } from "react";
import EmandateHistoryTable from "./EmandateHistoryTable";
import { useGetMandate } from "../../../../api/admin_mutation";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useUserPermission } from "../../../../hooks/useUserPermission";
import { Select } from "../../../common/form_inputs/Select";
import moment from "moment";
import Pagination from "../../../common/form_inputs/Pagination";
import { Spin } from "../../../common/form_inputs/Spin";
const { Search } = Input;

export default function EmandateHistory({ isAdmin }) {
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const [currentData, setCurrentData] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [filterBy, setFilterBy] = useState("customer")

  const {
    user_permission,
    isLoading: permissionLoading,
    isError: permissionError,
  } = useUserPermission();


  // const { data, isLoading, isError } = useGetMandates({
  //   status: ["inactive"],
  // });

  const {
    mutate: mutateGetMandate,
    data,
    isPending: isLoading,
    isError,
  } = useGetMandate(isAdmin);

  const mandateStatus = useMemo(()=>["inactive"], []);

  useEffect(() => {
    mutateGetMandate({
      status: mandateStatus,
    });
  }, [mandateStatus, mutateGetMandate]);

  const handleChangeMandateDate = (value) => {
    const startDate = moment().startOf(value).format("YYYY/MM/DD");
    const endDate = moment().endOf(value).format("YYYY/MM/DD");

    mutateGetMandate({
      start_date: startDate,
      end_date: endDate,
      status: mandateStatus,
    });
  };

  const response = data?.data;

  useEffect(() => {
    if (!isLoading && response?.data) {
      // Only update the currentData once the loading is done and new data has arrived
      setCurrentData(response?.data);
    }
  }, [isLoading, response]); // Update data when the loading finishes and response changes

  const tableData = useMemo(() => {
    return response?.data;
  }, [response]);



  // useEffect(() => {
  //   const filterData = () => {
  //     if (searchValue?.trim()) {
  //       const value = searchValue?.toLowerCase();
  //       const updatedData = tableData?.filter((item) => {
  //         const matches = [
  //           item?.first_name?.toLowerCase(),
  //           item?.last_name?.toLowerCase(),
  //         ].some((field) => field?.includes(value));
  //         return matches;
  //       });

  //       setFilteredData(updatedData?.length ? updatedData : []);
  //     } else {
  //       // If search is cleared, show full data
  //       setFilteredData(tableData);
  //     }
  //   };

  //   filterData();
  // }, [searchValue, tableData]);

  useEffect(() => {
    const filterData = () => {
      if (tableData) {
        if (searchValue?.trim()) {
          const value = searchValue?.toLowerCase();
          const updatedData = tableData?.filter((item) => {
            const customerMatch = [
              item?.first_name?.toLowerCase(),
              item?.last_name?.toLowerCase(),
            ]
            const businessMatches = [
              item?.business_name?.toLowerCase()
            ]
  
            const filterMatch = filterBy==='customer'?customerMatch:businessMatches
  
            const matches = filterMatch.some((field) => field?.includes(value));
            return matches;
          });

          setFilteredData(updatedData?.length ? updatedData : []);
        } else {
          // If search is cleared, show full data
          setFilteredData(tableData);
        }
      }
    };

    filterData();
  }, [searchValue, tableData, filterBy]); // Add tableData to the dependency array

  // const tableRows = searchValue?.length ? filteredData : tableData;

  const tableRows = searchValue?.length ? filteredData : currentData || [];

  const handleOpenDrawer = (data) => {
    openDrawer("REPAYMENT_SCHEDULE", { schedule: data });
    updateDrawerTitle("Mandate Status");
  };


  const handlePaginateChange = (page) => {
    mutateGetMandate({
      page,
      status: mandateStatus,
    });
    // setCurrentPage(page);
  };



  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">
          {isAdmin ? "Request" : "Recent Mandate"}
        </h3>
        {isAdmin && (
          <div className="mt-3 mb-2">
            <Select
              placeholder="Filter by business"
              size="small"
              defaultValue={filterBy}
              optionFilterProp="label"
              onChange={(value)=>setFilterBy(value)}
              options={[
                {
                  value: "customer",
                  label: "Customer",
                },
                {
                  value: "business",
                  label: "Business",
                }
              ]}
            />
          </div>
        )}
        <div className="flex justify-between mb-3">
          <div>
            <Search
              placeholder="Search Name"
              onSearch={onSearch}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{
                width: 200,
              }}
            />
          </div>
          <div className="flex gap-x-2">
            <Select
              size="small"
              defaultValue="all_time"
              style={{ width: 120 }}
              onChange={handleChangeMandateDate}
              options={[
                { value: "all_time", label: "All time" },
                { value: "today", label: "Today" },
                { value: "week", label: "This week" },
                { value: "month", label: "This month" },
                { value: "year", label: "This year" },
              ]}
            />
          </div>
        </div>

        {
          isLoading && !tableRows?.length ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          )
          :
          isError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : permissionLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : permissionError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : user_permission?.view_mandate ? (
            <div className="relative">
              <EmandateHistoryTable
                rows={tableRows}
                handleOpen={handleOpenDrawer}
              />
               {isLoading && (
                <div className="flex justify-center">
                  <Spin />
                </div>
              )}
              <div className="mt-2">
                <Pagination
                  current={response?.currentPage}
                  align={"end"}
                  total={response?.totalRecords}
                  onChange={handlePaginateChange}
                />
              </div>
            </div>
          ) : (
            <div className="text-center my-10">
              <i className="text-lg">You are not permitted to view mandates</i>
            </div>
          )
        }
      </main>
    </>
  );
}
