import React, { useRef, useState } from "react";
import { Input } from "../../../common/form_inputs/Input";
import { TextArea } from "../../../common/form_inputs/TextArea";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useCreatePlan } from "../../../../api/admin_mutation";
import { useUploadImage } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useBankList } from "../../../../hooks/useBankList";
import { useProfileData } from "../../../../hooks/useProfileData";
import { Select } from "../../../common/form_inputs/Select";

export default function CreatePlan() {
  //external hooks
  const {
    closeDrawer,
    data: { company_detail },
  } = useDrawer();

  const mutation = useCreatePlan();

  const { data: admin_detail } = useProfileData();

  const { data, isLoading: bankListLoading } = useBankList();

  const uploadImageMutation = useUploadImage();

  const bank_list = data?.map((bank) => {
    return {
      ...bank,
      value: bank?.name,
      label: bank?.name,
    };
  });

  const { showMessage, contextHolder } = useCustomMessage();

  //

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      bank_name: "",
      account_number: "",
      bank_code: "",
    },
  });

  const fileInputRef = useRef(null);

  const onsubmit = async (data) => {
    const json = {
      name: data?.name,
      check_balance: data?.check_balance,
      number_of_mandate: data?.number_of_mandate,
      number_of_business_mandate: data?.number_of_business_mandate ?? 0,
      transaction_fee: data?.transaction_fee,
      debit_fee: data?.debit_fee,
      number_of_debit: data?.number_of_debit,
      number_of_admin: data?.number_of_admin,
      description: data?.description,
      plan_type: data?.plan_type,
    };

    setIsLoading(true);

    if (data?.image) {
      const formData = new FormData();
      formData.append("image", data?.image);
      try {
        const res = await uploadImageMutation.mutateAsync(formData);
        json.image = res?.data?.data;
      } catch (error) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        showMessage("error", errMsg);
        // Stop further execution
        return;
      }
    }

    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        reset({
          name: "",
          check_balance: "",
          number_of_mandate: "",
          number_of_business_mandate: "",
          transaction_fee: "",
          debit_fee: "",
          number_of_debit: "",
          number_of_admin: "",
          description: "",
          plan_type: "",
        });

        showMessage("success", res?.message);
        closeDrawer();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const handleChange = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName);
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto">
        <form action="" onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Name
            </label>
            <Input
              name="name"
              defaultValue={getValues("name")}
              placeholder="Enter Plan Name"
              size={"large"}
              className={` text-[14px] ${errors.name ? "error" : ""}`}
              status={errors.name ? "error" : ""}
              {...register("name", {
                required: "Plan Name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.name && errors.name.message}
            </small>
          </div>
          <div className="mb-3 grid grid-cols-2 gap-4">
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Mandate
              </label>
              <Input
                name="number_of_mandate"
                defaultValue={getValues("number_of_mandate")}
                type="number"
                placeholder="Enter number of mandate"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_mandate ? "error" : ""
                }`}
                status={errors.number_of_mandate ? "error" : ""}
                {...register("number_of_mandate", {
                  required: "Number of mandate is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_mandate")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_mandate && errors.number_of_mandate.message}
              </small>
            </div>
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Number of Admin
              </label>
              <Input
                name="number_of_admin"
                defaultValue={getValues("number_of_admin")}
                type="number"
                placeholder="Enter number of admin"
                size={"large"}
                className={` text-[14px] ${
                  errors.number_of_admin ? "error" : ""
                }`}
                status={errors.number_of_admin ? "error" : ""}
                {...register("number_of_admin", {
                  required: "Number of admin is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "number_of_admin")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.number_of_admin && errors.number_of_admin.message}
              </small>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Number of Business Mandate
            </label>
            <Input
              name="number_of_business_mandate"
              defaultValue={getValues("number_of_business_mandate")}
              type="number"
              disabled={true}
              placeholder="Enter number of business mandate"
              size={"large"}
              className={` text-[14px] ${
                errors.number_of_business_mandate ? "error" : ""
              }`}
              status={errors.number_of_business_mandate ? "error" : ""}
              {...register("number_of_business_mandate", {
                // required: "Number of business mandate is required",
              })}
              onChange={(e) =>
                handleChange(e.target.value, "number_of_business_mandate")
              }
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.number_of_business_mandate &&
                errors.number_of_business_mandate.message}
            </small>
          </div>

          <div className="mb-3 grid grid-cols-2 gap-4">
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Transaction Fee%
              </label>
              <Input
                name="transaction_fee"
                defaultValue={getValues("transaction_fee")}
                type="number"
                placeholder="Enter Transaction Fee"
                size={"large"}
                className={` text-[14px] ${
                  errors.transaction_fee ? "error" : ""
                }`}
                status={errors.transaction_fee ? "error" : ""}
                {...register("transaction_fee", {
                  required: "Transaction fee is required",
                })}
                onChange={(e) =>
                  handleChange(e.target.value, "transaction_fee")
                }
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.transaction_fee && errors.transaction_fee.message}
              </small>
            </div>
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Debit Fee
              </label>
              <Input
                name="debit_fee"
                defaultValue={getValues("debit_fee")}
                type="number"
                placeholder="Enter debit fee"
                size={"large"}
                className={` text-[14px] ${errors.debit_fee ? "error" : ""}`}
                status={errors.debit_fee ? "error" : ""}
                {...register("debit_fee", {
                  required: "Debit fee is required",
                })}
                onChange={(e) => handleChange(e.target.value, "debit_fee")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.debit_fee && errors.debit_fee.message}
              </small>
            </div>
          </div>
          <div className="mb-3 grid grid-cols-2 gap-4">
            {/* <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Check Balance
              </label>
              <Input
                name="check_balance"
                defaultValue={getValues("check_balance")}
                type="number"
                placeholder="Enter number to check balance"
                size={"large"}
                className={` text-[14px] ${
                  errors.check_balance ? "error" : ""
                }`}
                status={errors.check_balance ? "error" : ""}
                {...register("check_balance", {
                  required: "Number to check balance is required",
                })}
                onChange={(e) => handleChange(e.target.value, "check_balance")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.check_balance && errors.check_balance.message}
              </small>
            </div> */}
            <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Number of Debit
            </label>
            <Input
              name="number_of_debit"
              defaultValue={getValues("number_of_debit")}
              type="number"
              placeholder="Enter number of debit"
              size={"large"}
              className={` text-[14px] ${
                errors.number_of_debit ? "error" : ""
              }`}
              status={errors.number_of_debit ? "error" : ""}
              {...register("number_of_debit", {
                required: "Number of debit is required",
              })}
              onChange={(e) =>
                handleChange(e.target.value, "number_of_debit")
              }
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.number_of_debit &&
                errors.number_of_debit.message}
            </small>
          </div>
            <div className="">
              <label htmlFor="" className="font-[400] text-[14px]">
                Plan Type
              </label>
              <Select
                defaultValue={getValues("plan_type")}
                showSearch
                placeholder="Select plan type"
                className="w-full"
                optionFilterProp="label"
                status={errors.plan_type ? "error" : ""}
                {...register("plan_type", {
                  required: "Plan Type is required",
                })}
                onChange={(value) => {
                  setValue("plan_type", value);
                  trigger("plan_type");
                }}
                options={[
                  { label: "Month", value: "monthly" },
                  // { label: "Quarter", value: "quarters" },
                  // { label: "Annual", value: "annual" },
                ]}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.plan_type && errors.plan_type.message}
              </small>
            </div>
          </div>

          <div className="mb-3 flex flex-col col-span-2">
            <label htmlFor="" className="font-[400] text-[14px]">
              Business description
            </label>
            <TextArea
              defaultValue={getValues("description")}
              rows={2}
              placeholder="Business description"
              {...register("description")}
              onChange={(e) => handleChange(e.target.value, "description")}
            />
          </div>

          <div className="mb-3 flex flex-col col-span-2">
            <label htmlFor="" className="font-[400] text-[14px]">
              Plan Image
            </label>
            <Input
              type={"file"}
              size="large"
              ref={fileInputRef}
              onChange={(e) => handleChange(e.target.files[0], "image")}
            />
          </div>

          <div className="mb-3">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={isLoading}
            >
              Create Plan
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
}
