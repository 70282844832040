import React, { useMemo } from "react";
import { SettingTabs } from "../../../components/specific/settings/setting_tabs/SettingTabs";
import { useSearchParams } from "react-router-dom";
import { ProfileView } from "../../../components/specific/settings/profile/ProfileView";
import { BusinessInfoView } from "../../../components/specific/settings/business_info/BusinessInfoView";
import { PreferencesView } from "../../../components/specific/settings/preferences/PreferencesView";
import { AccountView } from "../../../components/specific/settings/accounts/AccountView";
import { PermissionView } from "../../../components/specific/settings/permissions/PermissionView";
import { TeamsView } from "../../../components/specific/settings/teams/TeamsView";
import { TimelineView } from "../../../components/specific/settings/timeline/TimelineView";
import CustomButton from "../../../components/common/CustomButton";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { useProfileData } from "../../../hooks/useProfileData";

const tabs = [
  {
    component: ProfileView,
    value: "",
  },
  {
    component: ProfileView,
    value: "profile",
  },
  {
    component: BusinessInfoView,
    value: "business info",
  },
  {
    component: PreferencesView,
    value: "preferences",
  },
  {
    component: AccountView,
    value: "accounts",
  },
  {
    component: PermissionView,
    value: "permissions",
  },
  {
    component: TeamsView,
    value: "team",
  },
  {
    component: TimelineView,
    value: "timeline",
  },
];

export const Settings = () => {
  const { openDrawer, updateDrawerTitle } = useDrawer();

  const { isAdmin } = useProfileData();

  const [searchParams] = useSearchParams();

  const currentTab = searchParams.get("tab");

  const renderView = tabs.find((tab) => tab.value === currentTab) ?? tabs[0];

  const handleOpenDrawer = () => {
    openDrawer("CREATE_PLAN");
    updateDrawerTitle("Create Plan");
  };

  return (
    <>
      <main className="">
        <div className="flex justify-between">
          <h3 className="font-[500] text-[18px] my-2 text-black capitalize">
            {currentTab}
          </h3>
          {isAdmin && (
            <CustomButton size="sm" className={""} onClick={handleOpenDrawer}>
              Create Plan
            </CustomButton>
          )}
        </div>
        {/* <div className="flex justify-center"> */}
        <SettingTabs />
        {/* </div> */}

        <section className="mt-10">
          <renderView.component />
        </section>
      </main>
    </>
  );
};
