import { OtpInput } from "../../../common/form_inputs/OtpInput";
import { Button } from "antd";
import CustomButton from "../../../common/CustomButton";

const VerificationOtp = () => {
  const onChange = (text) => {
//     console.log("onChange:", text); //this is the full value of the input
  };

  const onInput = (value) => {
    // console.log("onInput:", value.target.value); //this is each of the input value
  };
  const sharedProps = {
    onChange,
    onInput,
  };



  const handleContinue=()=>{
    console.log("continue")
  }

  return (
    <>
      <main className="flex flex-col gap-y-8">
        <h3 className="font-[500] text-[16px] text-center">Enter OTP Code</h3>
        <p className="font-[400] text-[14px] leading-[21px] text-[#5F5955]">
          An OTP code has been sent to {"08012345678"}. Kindly input it.
        </p>
        <div className="flex flex-col gap-y-4 justify-center items-center">
          <OtpInput length={6} size={'large'} {...sharedProps} />
          <div className="flex justify-between">
            <Button type="text" className="text-red-500">Resend Code</Button>
            <Button type="text" className="text-red-500">Edit Phone Number</Button>
          </div>
        </div>
        <div >
            <CustomButton onClick={handleContinue} className="w-full">
                Continue
            </CustomButton>
        </div>
      </main>
    </>
  );
};

export default VerificationOtp;
