import { ConfigProvider, Pagination as AntPagination } from 'antd'
import React from 'react'

const Pagination = ({...rest}) => {
  return (
    <ConfigProvider
    theme={{
     
     token: {
       colorPrimary: "#733534",
     }
   }}
   >
    <AntPagination {...rest}/>

</ConfigProvider>
  )
}

export default Pagination