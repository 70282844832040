import React, { useEffect, useState } from "react";
import { Input } from "../../../common/form_inputs/Input";
import { Select } from "../../../common/form_inputs/Select";
import { TextArea } from "../../../common/form_inputs/TextArea";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useAddCompany } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useBankList } from "../../../../hooks/useBankList";
import { useProfileData } from "../../../../hooks/useProfileData";

export default function OnboardCompany() {
  //external hooks
  const {
    closeDrawer,
    data: { company_detail },
  } = useDrawer();

  const mutation = useAddCompany();

  const { data:admin_detail } = useProfileData();

  const { data, isLoading: bankListLoading } = useBankList();

  const bank_list = data?.map((bank) => {
    return {
      ...bank,
      value: bank?.name,
      label: bank?.name,
    };
  });

  const { showMessage, contextHolder } = useCustomMessage();

  //

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      bank_name: "",
      account_number: "",
      bank_code: "",
    },
  });

  //   useEffect(() => {
  //     const selected = bank_list?.find((bank) => bank?.value === company_detail?.bank_name);
  //     reset({
  //       bank_name: company_detail?.bank_name,
  //       account_name: company_detail?.account_name,
  //       account_number: company_detail?.account_number,
  //       bank_code: selected?.bank_code,
  //       nip_code: selected?.nip_code,
  //     });
  //   }, [company_detail, reset]);

  const onsubmit = (data) => {
    const json = {
    company: {
        bank_name: data?.bank_name,
        account_number: data?.account_number,
        bank_code: data?.bank_code,
        nip_code: data?.nip_code,
        name: data?.name,
        phone: data?.phone,
        email: data?.email,
        description: data?.description,
        location: data?.location,
        address: data?.address,
        category: data?.category,
    },
    admin: {
        first_name: admin_detail?.first_name,
        last_name: admin_detail?.last_name,
        phone: admin_detail?.phone,
        email: admin_detail?.email,
        profile_picture: admin_detail?.profile_picture,
    }
    };


    console.log(json)


    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        reset();

        showMessage("success", res?.message);
        closeDrawer();
      },
    });
  };

  const handleChange = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName);
  };

  const onChange = (value) => {
    const selected = bank_list.find((bank) => bank?.value === value);

    setValue("bank_name", selected?.name);
    setValue("bank_code", selected?.bank_code);
    setValue("nip_code", selected?.nip_code);

    trigger("bank_name");
    trigger("bank_code");
    trigger("nip_code");
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto">
        <p className="my-5 font-[500] text-[16px] text-black capitalize">
          Company Information
        </p>
        <form action="" onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Company Name
            </label>
            <Input
              name="name"
              defaultValue={getValues("name")}
              placeholder="Enter Company Name"
              size={"large"}
              className={` text-[14px] ${errors.name ? "error" : ""}`}
              status={errors.name ? "error" : ""}
              {...register("name", {
                required: "Company Name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.name && errors.name.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="font-[400] text-[14px]">
              Email Address
            </label>
            <Input
              name="email"
              defaultValue={getValues("email")}
              type="email"
              placeholder="Enter Email"
              size={"large"}
              className={` text-[14px] ${errors.email ? "error" : ""}`}
              status={errors.email ? "error" : ""}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
              onChange={(e) => handleChange(e.target.value, "email")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.email && errors.email.message}
            </small>
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="font-[400] text-[14px]">
              Phone Number
            </label>
            <Input
              name="phone"
              defaultValue={getValues("phone")}
              type="number"
              placeholder="Enter Phone number"
              size={"large"}
              className={` text-[14px] ${errors.phone ? "error" : ""}`}
              status={errors.phone ? "error" : ""}
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^0\d{10}$/,
                  message:
                    "Phone number must start with 0 and be 11 digits long",
                },
              })}
              onChange={(e) => handleChange(e.target.value, "phone")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.phone && errors.phone.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Bank Name
            </label>
            <Select
              defaultValue={getValues("bank_name")}
              showSearch
              placeholder="Select a person"
              className="w-full"
              optionFilterProp="label"
              loading={bankListLoading}
              status={errors.bank_name ? "error" : ""}
              options={bank_list}
              {...register("bank_name", {
                required: "Bank Name is required",
              })}
              onChange={onChange}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.bank_name && errors.bank_name.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Account Name
            </label>
            <Input
              name="account_name"
              defaultValue={getValues("account_name")}
              placeholder="Enter Account Name"
              size={"large"}
              className={` text-[14px] ${errors.account_name ? "error" : ""}`}
              status={errors.account_name ? "error" : ""}
              {...register("account_name", {
                required: "Account Name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "account_name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.account_name && errors.account_name.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px]">
              Account Number
            </label>
            <Input
              name="account_number"
              defaultValue={getValues("account_number")}
              type="number"
              placeholder="Enter Account Number"
              size={"large"}
              className={` text-[14px] ${errors.account_number ? "error" : ""}`}
              status={errors.account_number ? "error" : ""}
              {...register("account_number", {
                required: "Account Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Account Number must be 10 digits long",
                },
              })}
              onChange={(e) => handleChange(e.target.value, "account_number")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.account_number && errors.account_number.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Address
            </label>
            <Input
              name="address"
              defaultValue={getValues("address")}
              placeholder="FI lender"
              size={"large"}
              className={` text-[14px] ${errors.address ? "error" : ""}`}
              status={errors.address ? "error" : ""}
              {...register("address", {
                required: "Business address is required",
              })}
              onChange={(e) => handleChange(e.target.value, "address")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.address && errors.address.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Business Location
            </label>
            <Input
              name="location"
              defaultValue={getValues("location")}
              placeholder="FI lender"
              size={"large"}
              className={` text-[14px] ${errors.location ? "error" : ""}`}
              status={errors.location ? "error" : ""}
              {...register("location")}
              onChange={(e) => handleChange(e.target.value, "location")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.location && errors.location.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Category
            </label>
            <Select
            name="category"
              defaultValue={getValues("category")}
              showSearch
              placeholder="Select business category"
              className="w-full"
              optionFilterProp="label"
              loading={bankListLoading}
              status={errors.category ? "error" : ""}
              options={[
                {label: "Finance", value: "finace"},
                {label: "Technology", value: "technology"},
              ]}
              {...register("category", {
                // required: "Category is required",
              })}
              onChange={(value)=>{
                setValue("category", value)
                trigger("category")
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.category && errors.category.message}
            </small>
          </div>
          <div className="mb-3 flex flex-col col-span-2">
            <label htmlFor="" className="font-[400] text-[14px]">
              Business description
            </label>
            <TextArea
              rows={4}
              placeholder="Business description"
              {...register("description")}
              onChange={(e) => handleChange(e.target.value, "description")}
            />
          </div>

          <div className="mt-10 mb-3">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={mutation?.isPending}
            >
              {company_detail ? "Update" : "Add"}
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
}
