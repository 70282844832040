import { useState, useEffect } from "react";
import { useInitiateMandate } from "../../../../hooks/zustand_hooks/useInitiateMandate";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useFormik } from "formik";
import * as yup from "yup";
import InitiateMandateDrawer from "../../../common/drawer/InitiateMandateDrawer";
import CustomButton from "../../../common/CustomButton";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/form_inputs/Input";
import { useUserPermission } from "../../../../hooks/useUserPermission";
import { getUserByID } from "../../../../api/fetchingFn";
import { useSearchParams } from "react-router-dom";
import { FaHourglassEnd } from "react-icons/fa6";

export default function BusinessInformation({ handleClick }) {
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const userID = searchParams.get("user_id");
  const new_mandate = searchParams.get("new_mandate");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      bvn: "",
      phone: "",
      email: "",
    },
  });

  useEffect(() => {
    if (new_mandate) {
      getUserByID({ queryKey: ["getUserByID", userID] }).then((data) => {
        const user = data?.data?.data?.user;
        if (user) {
          openDrawer(
            "MANDATE_INFO",
            {
              values: {
                first_name: user?.first_name,
                last_name: user?.last_name,
                email: user?.email,
                phone: user?.phone,
                bvn: user?.bvn,
                address: user?.address,
              },
            },
            "MANDATE_USER_FORM"
          );
        }
      });
    }
  }, [searchParams, userID]);

  const { openDrawer, closeDrawer } = useDrawer();

  const { user_permission } = useUserPermission();

  const phonePattern = /^0\d{10}$/;

  const onSubmit = (data) => {
    console.log(data);
    openDrawer("MANDATE_INFO", { values: data });
    reset();
  };

  const onChangeNumber = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };
  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg border border-[#D9D9D996] bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Business Information</h4>

{/*           
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="business_name" className="font-[400] text-[14px]">
                Business Name
              </label>
              <Input
                name="business_name"
                defaultValue={getValues("business_name")}
                placeholder="Enter Business Name"
                size={"large"}
                className={`text-[14px] ${errors.business_name ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.business_name ? "error" : ""}
                {...register("business_name", {
                  required: "Business name is required",
                })}
                onChange={(e) => onChangeNumber(e, "business_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.business_name && errors.business_name.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="first_name" className="font-[400] text-[14px]">
                Customer’s First Name
              </label>
              <Input
                name="first_name"
                defaultValue={getValues("first_name")}
                placeholder="Enter Borrower’s First Name"
                size={"large"}
                className={`text-[14px] ${errors.first_name ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.first_name ? "error" : ""}
                {...register("first_name", {
                  required: "First name is required",
                })}
                onChange={(e) => onChangeNumber(e, "first_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.first_name && errors.first_name.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="last_name" className="font-[400] text-[14px]">
                Customer’s Last Name
              </label>
              <Input
                name="last_name"
                defaultValue={getValues("last_name")}
                placeholder="Enter Borrower’s Last Name"
                size={"large"}
                className={`text-[14px] ${errors.last_name ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.last_name ? "error" : ""}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                onChange={(e) => onChangeNumber(e, "last_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.last_name && errors.last_name.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="bvn" className="font-[400] text-[14px]">
                Customer’s BVN
              </label>
              <Input
                name="bvn"
                defaultValue={getValues("bvn")}
                type="number"
                placeholder="Enter BVN"
                size={"large"}
                className={`text-[14px] ${errors.bvn ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.bvn ? "error" : ""}
                {...register("bvn", { required: "BVN is required" })}
                onChange={(e) => onChangeNumber(e, "bvn")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.bvn && errors.bvn.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="font-[400] text-[14px]">
                Customer’s Phone
              </label>
              <Input
                name="phone"
                defaultValue={getValues("phone")}
                type="number"
                placeholder="Enter Phone number"
                size={"large"}
                className={`text-[14px] ${errors.phone ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                onChange={(e) => onChangeNumber(e, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="font-[400] text-[14px]">
                Customer’s E-mail
              </label>
              <Input
                name="email"
                defaultValue={getValues("email")}
                type="email"
                placeholder="Enter Email"
                size={"large"}
                className={`text-[14px] ${errors.email ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.email ? "error" : ""}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => onChangeNumber(e, "email")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.email && errors.email.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Customer’s Address
              </label>
              <Input
                name="address"
                defaultValue={getValues("address")}
                type="text"
                placeholder="Enter address"
                size={"large"}
                className={`text-[14px] ${errors.address ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.address ? "error" : ""}
                {...register("address", {
                  required: "address is required",
                })}
                onChange={(e) => onChangeNumber(e, "address")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.address && errors.address.message}
              </small>
            </div>

            <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                loading={isLoading}
                disabled={!user_permission?.create_mandate}
                // onClick={handleSubmitn}
              >
                Create mandate request
              </CustomButton>
            </div>
          </form> */}

          <h2 className="h-48 flex items-center justify-center text-gray-500 text-lg flex-col gap-y-1">
            <FaHourglassEnd size={30}/>
            <i>Page Coming soon...</i>
            </h2>
        </div>
      </div>

      <InitiateMandateDrawer handleClick={handleClick} />
    </>
  );
}
