import React from 'react'
import { Spin as AntSpin, ConfigProvider } from "antd"

export const Spin = ({className, ...rest}) => {
  return (
    <>
    <ConfigProvider 
     theme={{
      token: {
        colorPrimary: "#733534",
      }
    }}
    >

         <AntSpin
         
               {...rest}
              />

        </ConfigProvider>
    </>
  )
}
