import React, { useMemo } from "react";
import PlanCard from "./PlanCard";
import { useGetPlans } from "../../../../api/getHooks";
import { Result } from "antd";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { Spin } from "../../../common/form_inputs/Spin";

const ViewPlans = () => {
  const { data, isLoading, isError } = useGetPlans();

  const { data: { company }} = useDrawer()

  const plans = useMemo(() => {
    return data?.data?.data;
  }, [data]);


  console.log(company)

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center">
          <Spin />
        </div>
      ) : isError ? (
        <Result status="error" title="Unexpected error occurred" extra={null} />
      ) : (
        <main className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
          {plans?.map((plan, index) => (
            <PlanCard key={index + plan?.id + "__plan"} plan={plan} companyId={company?.id} active={company?.subscriptions?.[0]?.planId===plan?.id}/>
          ))}
        </main>
      )}
    </>
  );
};

export default ViewPlans;
