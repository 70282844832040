import React from 'react'
import { Input as AntInput, ConfigProvider } from "antd"

const { TextArea: AntTextArea } = AntInput

export const TextArea = ({className, ...rest}) => {
  return (
    <>
    <ConfigProvider theme={{
            components: {
                Input: {
                    hoverBorderColor: "#733534",
                    activeBorderColor: "#733534",
                    activeShadow: "0 0 0 0px #733534",
                }
            }
        }}>

         <AntTextArea
               {...rest}
                className={`h-[42px] text-[14px] ${className}`}
              />

        </ConfigProvider>
    </>
  )
}
