import React from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../../../common/form_inputs/Input";
import CustomButton from "../../../../common/CustomButton";
import { Select } from "../../../../common/form_inputs/Select";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";


const AddressInformation = () => {

const {
    changeStep,
    updateData,
    data: { form_values }
  } = useCustomerLoanApplication();


    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        trigger,
        getValues,
      } = useForm();


      const onSubmit = (data) => {
        updateData({ form_values: { ...form_values, ...data }})
        changeStep(3);
      };



      const onChangeValue = (e, fieldName) => {
        const value = e.target.value;
    
        setValue(fieldName, value);
        trigger(fieldName);
      };

  return (
    <>
      <main className="flex flex-col gap-y-7 my-5">
        <h3 className="font-[500] text-[16px] text-center">Address Information</h3>
        
        <div>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="home_address" className="font-[400] text-[14px]">
                Home Address
              </label>
              <Input
                name="home_address"
                defaultValue={getValues("home_address")}
                placeholder="e.g 37, Lemon street"
                size={"large"}
                className={`text-[14px] ${errors.home_address ? "error" : ""}`}
                status={errors.home_address ? "error" : ""}
                {...register("home_address", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "home_address")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.home_address && errors.home_address.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="land_mark" className="font-[400] text-[14px]">
                Landmark
              </label>
              <Input
                name="land_mark"
                defaultValue={getValues("land_mark")}
                placeholder="Closest bus stop"
                size={"large"}
                className={`text-[14px] ${errors.land_mark ? "error" : ""}`}
                status={errors.land_mark ? "error" : ""}
                {...register("land_mark", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "land_mark")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.land_mark && errors.land_mark.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="city" className="font-[400] text-[14px]">
                City
              </label>
              <Input
                name="city"
                defaultValue={getValues("city")}
                placeholder="e.g Ikeja"
                size={"large"}
                className={`text-[14px] ${errors.city ? "error" : ""}`}
                status={errors.city ? "error" : ""}
                {...register("city", {
                  required: false,
                 
                })}
                onChange={(e) => onChangeValue(e, "city")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.city && errors.city.message}
              </small>
            </div>
          
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                State
              </label>
              <Select
              defaultValue={getValues("state")}
              placeholder="Select state"
              className="w-full"
              optionFilterProp="label"
              status={errors.state ? "error" : ""}
              
              {...register("state", {
                required: "state is required",
              })}
              onChange={(value)=>{
                setValue("state", value)
                trigger("state")
              }}
              options={[
                { value: "lagos", label: "Lagos"},
                { value: "kaduna", label: "Kaduna"}
              ]}
            />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.state && errors.state.message}
              </small>
            </div>

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                // loading={isLoading}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default AddressInformation;
