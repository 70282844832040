import React from "react";
import setting_naira from "../../../assets/setting_naira.png";
import { decryptData } from "../../../utils/cryptData";

function LoanApplicationLayout({
  customer_name,
  company,
  description,
  children,
}) {
  return (
    <>
      <main className="grid lg:grid-cols-3 h-screen overflow-y-hidden">
        <section className="col-span-1 bg-[#131842] lg:w-auto md:w-screen md:flex hidden justify-center px-6 pt-10">
          <div className="flex flex-col items-center gap-y-5">
            <h3 className="text-white font-[700] text-center text-[50px]">
              {decryptData(company?.name) ?? "FI Lender"}
            </h3>
            <div className="flex flex-col gap-y-3">
              <p className="text-white font-[600] text-center">
                Dear {decryptData(customer_name)},
              </p>
              <p className="text-white font-[500] text-center pb-9 ">
                {description}
              </p>
            </div>
            <div className="lg:block md:hidden">
              <img
                src={setting_naira}
                alt="Naira"
                className="w-[200px] h-[200px]"
              />
            </div>
          </div>
        </section>
        <section className="col-span-2 overflow-y-scroll lg:ms-10 md:ms-5">
          <div className="p-5">
            <p className="font-[600] text-[16px] text-center leading-[24px]">
              Loan Application
            </p>
          </div>
          <hr />
          <div className="mt-5">{children}</div>
        </section>
      </main>
    </>
  );
}

export default LoanApplicationLayout;
