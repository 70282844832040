import React from "react";
import enlace from "../../../assets/white_enlace.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../libs/routes";

const StaffAuthLayout = ({ title, children }) => {
        const navigate = useNavigate()

        const gotoLandingPage=()=>{
                navigate(ROUTES.home)
        }
  return (
    <>
      <main className="bg-[#131842] w-full h-screen flex justify-center items-center">
        <div>
          <div className="flex flex-col gap-y-8 justify-center items-center mb-5">
            <img src={enlace} alt="enlace logo" className="object-cover cursor-pointer" onClick={gotoLandingPage} width={100} height={100}/>
            <p className="text-white font-[600] text-[24px]">{title}</p>
          </div>
          {children}
        </div>
      </main>
    </>
  );
};

export default StaffAuthLayout;
