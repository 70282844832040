import { Result } from "antd";
import {
  useGetDueSchedule,
  useFetchMandateSchedules,
} from "../../../api/admin_mutation";
import LoanWithDrawers from "../../../components/common/drawer/LoanWithDrawers";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { exportExcel } from "../../../utils/exportExcel";
import { Select } from "../../../components/common/form_inputs/Select";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import Pagination from "../../../components/common/form_inputs/Pagination";
import { formatDate } from "../../../utils/formatDate";
import { formatNumberOnly } from "../../../utils/formatNumber";
import { Spin } from "../../../components/common/form_inputs/Spin";
import { IoEyeOutline } from "react-icons/io5";
import { Table } from "../../../components/common/form_inputs/Table";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";
import { decryptData } from "../../../utils/cryptData";

export default function DueSchedules() {
  const { openDrawer, updateDrawerTitle } = useDrawer();

  const { showMessage, contextHolder } = useCustomMessage();

  const [, setCurrentData] = useState([]);

  const [selectedScheduleId, setSelectedScheduleId] = useState(null);

  const {
    mutate: mutateGetSchedule,
    data,
    isPending,
    isError,
  } = useGetDueSchedule();

  const { mutate, isPending: isFetchingSchedules } = useFetchMandateSchedules();

  useEffect(() => {
    const due_payload = {
      start_date: moment().startOf("month").format("YYYY/MM/DD"),
      end_date: moment().endOf("month").format("YYYY/MM/DD"),
      page: 1,
    };

    mutateGetSchedule(due_payload);
  }, [mutateGetSchedule]);

  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    let startDate;
    let endDate;
    if (value === "next_month") {
      startDate = moment()
        .add(1, "month")
        .startOf("month")
        .format("YYYY/MM/DD");
      endDate = moment().add(1, "month").endOf("month").format("YYYY/MM/DD");
    } else if (value === "next_year") {
      startDate = moment().add(1, "year").startOf("year").format("YYYY/MM/DD");
      endDate = moment().add(1, "year").endOf("year").format("YYYY/MM/DD");
    } else if (value === "last_month") {
      startDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY/MM/DD");
      endDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY/MM/DD");
    } else if (value === "last_year") {
      startDate = moment()
        .subtract(1, "year")
        .startOf("year")
        .format("YYYY/MM/DD");
      endDate = moment().subtract(1, "year").endOf("year").format("YYYY/MM/DD");
    } else {
      startDate = moment().startOf(value).format("YYYY/MM/DD");
      endDate = moment().endOf(value).format("YYYY/MM/DD");
    }

    mutateGetSchedule({
      start_date: startDate,
      end_date: endDate,
      page: 1,
    });
  };

  const response = data?.data;

  useEffect(() => {
    if (!isPending && response?.data) {
      // Only update the currentData once the loading is done and new data has arrived
      setCurrentData(response?.data);
    }
  }, [isPending, response]);

  const exportAs = (value) => {
    exportExcel({
      excelData: dataSource, //responseData?.schedules,
      fileName: `Due schedules`,
    });
  };

  const tableData = useMemo(() => {
    return response?.data;
  }, [response]);

  const handlePaginateChange = (page) => {
    mutateGetSchedule({
      page,
    });
  };

  //================ shedules detail =====================

  const handleOpenDrawer = (detail) => {
    setSelectedScheduleId(detail?.id);
    mutate(detail?.mandateId, {
      onSuccess: (data) => {
        const res = data?.data?.data;
        openDrawer("DEBIT_ACCOUNT", {
          mandate: detail?.mandate,
          single_schedule: detail,
          accounts: res?.accounts,
        });
        updateDrawerTitle(`Debit Account`);
      },
      onError: (error) => {
        const errMsg = error?.response?.data?.message ?? error?.message;
        showMessage(errMsg, "error");
      },
    });
  };

  const columns = [
    {
      title: "Due Date",
      dataIndex: "start_date",
      render: (text) => formatDate(decryptData(text)),
    },
    {
      title: "Principal Payment",
      dataIndex: "amount",
      render: (text, row) => <>NGN {formatNumberOnly(decryptData(text))}</>,
    },
    {
      title: "Interest Payment",
      dataIndex: "interest",
      render: (text, row) => <>NGN {formatNumberOnly(decryptData(text))}</>,
    },
    {
      title: "Total amount Due",
      dataIndex: "total_amount_due",
      render: (text, row) => <>NGN {formatNumberOnly(decryptData(text))}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div
          className={`capitalize ma ${
            decryptData(text) === "not debit"
              ? "bg-red-100 text-red-900"
              : decryptData(text) === "part debited"
              ? "bg-yellow-100 text-yellow-900"
              : decryptData(text) === "fully debited"
              ? "bg-green-100 text-green-900"
              : decryptData(text) === "inactive"
              ? "bg-yellow-100 text-yellow-900"
              : decryptData(text) === "reschedule pending"
              ? "bg-purple-100 text-purple-900"
              : decryptData(text) === "reschedule declined"
              ? "bg-red-100 text-red-900"
              : decryptData(text) === "reschedule approved"
              ? "bg-green-100 text-green-900"
              : decryptData(text) === "active" && "bg-green-100 text-green-900"
          } px-2 rounded text-center`}
        >
          <i>{decryptData(text)}</i>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, row) => (
        <>
          <small
            className="cursor-pointer"
            onClick={() => handleOpenDrawer(row)}
          >
            {isFetchingSchedules && selectedScheduleId === row?.id ? (
              <Spin />
            ) : (
              <IoEyeOutline size={"18px"} />
            )}
          </small>
        </>
      ),
    },
  ];

  let dataSource = tableData?.map((data, i) => ({
    ...data,
    key: data?.id,
  }));

  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">Due Loans</h3>

        <div className="flex justify-end mb-3 gap-3 flex-wrap">
          <div className="flex gap-x-2">
            <Select
              size="small"
              defaultValue={"This month"}
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "month", label: "This Month" },
                { value: "next_month", label: "Next Month" },
                { value: "last_month", label: "Last Month" },
                { value: "next_year", label: "Next Year" },
                { value: "last_year", label: "Last Year" },
              ]}
            />
            <Select
              size="small"
              defaultValue="Export as:"
              style={{
                width: 120,
              }}
              onChange={exportAs}
              options={[
                {
                  value: "excel",
                  label: "Export as excel",
                },
              ]}
            />
          </div>
        </div>
        {isPending && !tableData?.length ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : isError ? (
          <Result
            status="error"
            title="Unexpected error occurred"
            extra={null}
          />
        ) : (
          <div className="relative">
            <Table
              header_bg={true}
              pagination={false}
              onRow={(row) => {
                return {
                  onClick: () => handleOpenDrawer(row), // Add click event
                };
              }}
              //   rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
              size="small"
              className={"w-full"}
              rowClassName="cursor-pointer hover:bg-gray-100"
            />

            {isPending && (
              <div className="flex justify-center z-10">
                <Spin />
              </div>
            )}
            <div className="mt-2">
              <Pagination
                current={response?.currentPage}
                align={"end"}
                total={response?.totalRecords}
                onChange={handlePaginateChange}
              />
            </div>
          </div>
        )}
      </main>

      <LoanWithDrawers />

      {contextHolder}
    </>
  );
}
