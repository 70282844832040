
import { useState } from "react";
import { HiOutlineCamera } from "react-icons/hi2";
import CustomButton from "../../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const SelfieIdentity = () => {

    const [file, setFile] = useState(null);

const {
    changeStep
  } = useCustomerLoanApplication();



  const handleChoosePhoto = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };



  const handleContinue=()=>{
    changeStep(1);
  }

  return (
    <>
      <main className="flex flex-col gap-y-7">
        <div>
          <h3 className="font-[500] text-[16px]">Complete KYC Process</h3>
          <p className="font-[300] text-[14px]">
            You won’t have to do this in your next loan application
          </p>
        </div>

        <div className="">
          <h3 className="font-[500] text-[16px] text-center">Take A Selfie</h3>
          <div className="border border-[#DBDBDB] rounded-[10px] h-52 w-56 mx-auto mt-3 flex justify-center items-center">
            <label
              htmlFor="upload_photo"
              className="flex flex-col items-center"
            >
              <input
                type="file"
                className="hidden"
                id="upload_photo"
                accept=".jpg, .png, .jpeg, .gif"
                onChange={handleChoosePhoto}
              />

              <HiOutlineCamera size={40} />
              <p className="font-[300] text-[16px]">Click to upload Photo</p>
            </label>
          </div>
        </div>
        <div>
            <CustomButton onClick={handleContinue} className={"w-full"}>Continue</CustomButton>
        </div>
      </main>
    </>
  );
};

export default SelfieIdentity;
