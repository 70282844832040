import React, { useEffect, useState } from "react";
import { Input } from "../../../common/form_inputs/Input";
import { Select } from "../../../common/form_inputs/Select";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useUpdateCompany } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useBankList } from "../../../../hooks/useBankList";

export const AddAccount = () => {
  //external hooks
  const {
    closeDrawer,
    data: { company_detail },
  } = useDrawer();

  const mutation = useUpdateCompany();

  const { data, isLoading: bankListLoading } = useBankList();

  const bank_list = data?.map((bank) => {
    return {
      ...bank,
      value: bank?.name,
      label: bank?.name,
    };
  });

  const { showMessage, contextHolder } = useCustomMessage();

  //

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      bank_name: "",
      account_name: "",
      account_number: "",
      bank_code: ""
    },
  });

  useEffect(() => {
    const selected = bank_list?.find((bank) => bank?.value === company_detail?.bank_name);
    reset({
      bank_name: company_detail?.bank_name,
      account_name: company_detail?.account_name,
      account_number: company_detail?.account_number,
      bank_code: selected?.bank_code,
      nip_code: selected?.nip_code,
    });
  }, [bank_list, company_detail, reset]);

  const onsubmit = (data) => {
    const json = {
      bank_name: data?.bank_name,
      account_name: data?.account_name,
      account_number: data?.account_number,
      bank_code: data?.bank_code,
      nip_code: data?.nip_code,
    };


    setIsLoading(true);

    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        reset();

        showMessage("success", res?.message);
        closeDrawer();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const handleChange = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName);
  };

  const onChange = (value) => {
    const selected = bank_list.find((bank) => bank?.value === value);


    setValue("bank_name", selected?.name);

    trigger("bank_name");
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto">
        <p className="my-5 font-[500] text-[16px] text-black capitalize">
          Bank Information
        </p>
        <form action="" onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px]">
              Bank Name
            </label>
            <Select
              defaultValue={getValues("bank_name")}
              showSearch
              placeholder="Select a person"
              className="w-full"
              optionFilterProp="label"
              status={errors.bank_name ? "error" : ""}
              
              {...register("bank_name", {
                required: "Bank Name is required",
              })}
              onChange={onChange}
              options={bank_list}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.bank_name && errors.bank_name.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px]">
              Account Number
            </label>
            <Input
              name="account_number"
              defaultValue={getValues("account_number")}
              type="number"
              placeholder="Enter Account Number"
              size={"large"}
              className={` text-[14px] ${errors.account_number ? "error" : ""}`}
              status={errors.account_number ? "error" : ""}
              {...register("account_number", {
                required: "Account Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Account Number must be 10 digits long",
                },
              })}
              onChange={(e) => handleChange(e.target.value, "account_number")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.account_number && errors.account_number.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px]">
              Account Name
            </label>
            <Input
              name="account_name"
              defaultValue={getValues("account_name")}
              placeholder="Enter Account Name"
              size={"large"}
              className={` text-[14px] ${errors.account_name ? "error" : ""}`}
              status={errors.account_name ? "error" : ""}
              {...register("account_name", {
                required: "Account Name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "account_name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.account_name && errors.account_name.message}
            </small>
          </div>

          <div className="mt-10 mb-3">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={isLoading}
            >
              {company_detail ? "Update" : "Add"}
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
};
