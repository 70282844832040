import { Result } from "antd";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const KycSubmitted = () => {
  const {
    updateCurrentPage,
  } = useCustomerLoanApplication();

  const handleContinue = () => {
    updateCurrentPage("LOAN_APPLICATION_SUMMARY", null);
  };

  return (
    <>
      <main>
        <Result
          status={"success"}
          title={
            <>
              <p className="font-[500] text-[24px] text-[#2A2A29]">
                KYC Process Complete
              </p>
              <br />
              <p>Your application will be reviewed</p>
            </>
          }
          extra={[
            <CustomButton
              className={"w-full max-w-lg"}
              onClick={handleContinue}
            >
              Continue to loan
            </CustomButton>,
          ]}
        />
      </main>
    </>
  );
};

export default KycSubmitted;
