import React from "react";
import avatar from "../../../../../assets/avatar.png";
import { Image } from "antd";
import { Controller, useForm } from "react-hook-form";
import { Input, InputNumber } from "../../../../common/form_inputs/Input";
import CustomButton from "../../../../common/CustomButton";
import { Select } from "../../../../common/form_inputs/Select";
import { DatePicker } from "../../../../common/form_inputs/DatePicker";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const PersonalInformation = () => {


const {
    changeStep,
    data: { form_values },
    updateData
  } = useCustomerLoanApplication();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        trigger,
        getValues,
        control
      } = useForm();


      const onSubmit = (data) => {
        
        updateData({ form_values: { ...form_values, ...data } })
        changeStep(2);
      };



      const onChangeValue = (e, fieldName) => {
        const value = e.target.value;
    
        setValue(fieldName, value);
        trigger(fieldName);
      };



      const handleKeyPress = (event) => {
        const inputValue = event.target.value;
    
        // Allow digits and a single decimal point
        if (
          !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
          (event.key === "." && inputValue.includes(".")) // Block multiple periods
        ) {
          event.preventDefault();
        }
      };


  return (
    <>
      <main className="flex flex-col gap-y-7 my-5">
        <h3 className="font-[500] text-[16px] text-center">Personal Information</h3>
        <div className="flex justify-center mb-8">
          <Image
            preview={false}
            className="rounded-lg border border-[#908A8A]"
            width={200}
            src={avatar}
          />
        </div>
        <div>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="first_name" className="font-[400] text-[14px]">
                First Name*
              </label>
              <Input
                name="first_name"
                defaultValue={getValues("first_name")}
                placeholder="Enter your First Name"
                size={"large"}
                className={`text-[14px] ${errors.first_name ? "error" : ""}`}
                status={errors.first_name ? "error" : ""}
                {...register("first_name", {
                  required: "First name is required",
                })}
                onChange={(e) => onChangeValue(e, "first_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.first_name && errors.first_name.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="last_name" className="font-[400] text-[14px]">
                Last Name*
              </label>
              <Input
                name="last_name"
                defaultValue={getValues("last_name")}
                placeholder="Enter your Last Name"
                size={"large"}
                className={`text-[14px] ${errors.last_name ? "error" : ""}`}
                status={errors.last_name ? "error" : ""}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                onChange={(e) => onChangeValue(e, "last_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.last_name && errors.last_name.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="phone" className="font-[400] text-[14px]">
                Phone Number*
              </label>
              <Input
                name="phone"
                defaultValue={getValues("phone")}
                type="number"
                placeholder="Enter Your Phone number"
                size={"large"}
                className={`text-[14px] ${errors.phone ? "error" : ""}`}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                onChange={(e) => onChangeValue(e, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="font-[400] text-[14px]">
                E-mail*
              </label>
              <Input
                name="email"
                defaultValue={getValues("email")}
                type="email"
                placeholder="Enter your Email"
                size={"large"}
                className={`text-[14px] ${errors.email ? "error" : ""}`}
                status={errors.email ? "error" : ""}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => onChangeValue(e, "email")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.email && errors.email.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Gender*
              </label>
              <Select
              defaultValue={getValues("gender")}
              placeholder="Select your gender"
              className="w-full"
              optionFilterProp="label"
              status={errors.gender ? "error" : ""}
              
              {...register("gender", {
                required: "Gender is required",
              })}
              onChange={(value)=>{
                setValue("gender", value)
                trigger("gender")
              }}
              options={[
                { value: "male", label: "male"},
                { value: "female", label: "Female"}
              ]}
            />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.gender && errors.gender.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Marital Status*
              </label>
              <Select
              defaultValue={getValues("marital_status")}
              placeholder="Select your marital status"
              className="w-full"
              optionFilterProp="label"
              status={errors.marital_status ? "error" : ""}
              
              {...register("marital_status", {
                required: "marital status is required",
              })}
              onChange={(value)=>{
                setValue("marital_status", value)
                trigger("marital_status")
              }}
              options={[
                { value: "single", label: "Single"},
                { value: "married", label: "Married"},
                { value: "divorce", label: "Divorce"}
              ]}
            />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.marital_status && errors.marital_status.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Date of Birth*
              </label>
              <DatePicker
              className={"w-full"}
              {...register("dob", {
                required: "Date of birth is required",
              })}
              status={errors.dob ? "error" : ""}
              onChange={(date, dateString)=>{
                setValue("dob", dateString)
                trigger("dob")
              }} />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.dob && errors.dob.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="nin" className="font-[400] text-[14px]">
                NIN*
              </label>
              <Controller
                name="nin"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/[^\d]/g, "")}
                    defaultValue={getValues("nin")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.nin ? "error" : ""
                    }`}
                    status={errors.nin ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("nin");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.nin && errors.nin.message}
              </small>
            </div>

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                // loading={isLoading}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default PersonalInformation;
