import { Radio, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { useSendSchedule } from "../../../../api/mutation";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import CustomButton from "../../../common/CustomButton";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/form_inputs/Input";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

export default function PenalChargeView() {
  const {
    openDrawer,
    data: { formData, schedules },
  } = useDrawer();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      penal_charges: "",
      grace_period: "",
      grace_period_type: "",
      over_grace_penal_charges: ""
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const { showMessage, contextHolder } = useCustomMessage();

  const mutation = useSendSchedule();

  const onSubmit = (data) => {
    
    //     const formData = new FormData();
    //     for (const key in values) {
    //       formData.append(key, values[key]);
    //     }

    formData?.append("penal_charges", String(data.penal_charges));
    formData?.append("grace_period", data.grace_period);
    formData?.append("grace_period_type", data.grace_period_type);
    formData?.append("over_grace_penal_charges", data.over_grace_penal_charges);

    // for (const key in data) {
    //   formData.append(key, data[key]);
    // }

    formData?.set("preview", "false");

    for (let [key, value] of formData?.entries()) {
          console.log(key, value, typeof value);
        }

    setIsLoading(true);
    mutation.mutate(formData, {
      onSuccess: (res) => {
        console.log(res);
        openDrawer("CREATE_SUCCESSFULL", { schedules: res?.data?.data });
        reset();
      },
    });
  };

  useEffect(() => {
    if (mutation.isPending) {
      setIsLoading(true);
    } else if (mutation.isError) {
      setIsLoading(false);
      const error = mutation?.error?.response?.data?.message;

      showMessage("error", error);
    } else if (mutation.isSuccess) {
      setIsLoading(false);
      const res = mutation?.data?.data?.data;
    }
  }, [mutation.status]);

  

  const onChange = (e) => {
    if (e.target.name === "penal_charge_penalty") {
      setValue(e.target.name, e.target.checked);
    } else {
      setValue(e.target.name, e.target.value);
    }
    trigger(e.target.name);
  };

  const onChangeNumber = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, String(value));
    // formik.setFieldValue(e.target.name, e.target.value)

    trigger(fieldName);
  };

  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-2xl px-6 py-4 rounded-lg border-[#D9D9D996] bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2 text-left">
            Set Penal Charges
          </h4>
          <p className="mt-3 font-[300] text-[14px]">
            Set the grace period after which defaulted borrowers start paying
            fine. You can skip this if it is not required by you.
          </p>
          <form
            action=""
            className="max-w-lg mx-auto mt-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                % of penal charge per day
              </label>
              <Input
                type="number"
                defaultValue={getValues("penal_charges")}
                name="penal_charges"
                {...register("penal_charges", {
                  required: "Penal charge is required",
                })}
                onChange={(e) => onChangeNumber(e, "penal_charges")}
                placeholder="Enter percentage you want to charge daily e.g. 0.2%"
                size={"large"}
                className={` text-[14px] placeholder:text-[#033F4B] ${
                  errors.penal_charges ? "error" : ""
                }`}
                status={errors.penal_charges ? "error" : ""}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.penal_charges && errors.penal_charges.message}
              </small>
            </div>

            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Grace period
              </label>
              <div>
                <Input
                  type="number"
                  defaultValue={getValues("grace_period")}
                  name="grace_period"
                  {...register("grace_period", {
                    required: "Grace period is required",
                  })}
                  onChange={(e) => onChangeNumber(e, "grace_period")}
                  placeholder="Enter number of days for grace period e.g. 1"
                  size={"large"}
                  className={` text-[14px] placeholder:text-[#033F4B] ${
                    errors.grace_period ? "error" : ""
                  }`}
                  status={errors.grace_period ? "error" : ""}
                />
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.grace_period && errors.grace_period.message}
                </small>
              </div>
              <Radio.Group
                onChange={(e) => onChange(e)}
                // {...register("grace_period_type", {
                //     required: "Period type is required",
                //     onChange: (e)=>onChange(e)
                //   })}'
                name="grace_period_type"
                defaultValue={getValues("grace_period_type")}
                className="mt-2"
              >
                <Radio value={"day"}>Day</Radio>
                <Radio value={"week"}>Week</Radio>
                <Radio value={"month"}>Month</Radio>
              </Radio.Group>
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.grace_period_type && errors.grace_period_type.message}
              </small>
            </div>

            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Set penal charge % per day after grace period
              </label>
              <div>
                <Input
                  type="number"
                  defaultValue={getValues("over_grace_penal_charges")}
                  name="over_grace_penal_charges"
                  {...register("over_grace_penal_charges", {
                    required: !watch("penal_charge_penalty")
                      ? "Grace period amount is required"
                      : false,
                  })}
                  onChange={(e) => onChangeNumber(e, "over_grace_penal_charges")}
                  placeholder="Enter percentage you want to charge daily e.g. 0.2%"
                  size={"large"}
                  className={` text-[14px] placeholder:text-[#033F4B] ${
                    errors.over_grace_penal_charges ? "error" : ""
                  }`}
                  status={errors.over_grace_penal_charges ? "error" : ""}
                />
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.over_grace_penal_charges &&
                    errors.over_grace_penal_charges.message}
                </small>
              </div>
              <Checkbox
                onChange={(e) => onChange(e)}
                name="penal_charge_penalty"
                defaultValue={getValues("penal_charge_penalty")}
              >
                Does not apply
              </Checkbox>
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.grace_period_type && errors.grace_period_type.message}
              </small>
            </div>

            <div className="mt-4 flex justify-center">
              <CustomButton
                type={"submit"}
                className="px-8 w-full"
                loading={isLoading}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
      {contextHolder}
    </>
  );
}
