import { useMemo } from "react";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { Controller, useForm } from "react-hook-form";
import { InputNumber } from "../../../common/form_inputs/Input";
import CustomButton from "../../../common/CustomButton";
import { Checkbox } from "../../../common/form_inputs/Checkbox";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const duration_type = [
//   { name: "day"},
//   { name: "week"},
  { name: "month"},
//   { name: "year"},
]


const LoanRequest = () => {
  const {
    data: { values },
  } = useDrawer();

  const { updateCurrentPage } = useCustomerLoanApplication();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
    control,
    watch
  } = useForm({
    defaultValues: {
      loan_amount: values?.loan_amount,
      duration_type: values?.duration_type,
      repayment_period: values?.max_repayment_period,
    },
    mode: "onBlur", // Validate on blur or change
    criteriaMode: "all",
  });

  const loan_amount = watch("loan_amount")
  const repayment_period = watch("repayment_period")


  const monthlyRepaymentAmount = useMemo(()=>(
      parseFloat(loan_amount) / parseFloat(repayment_period)
  ), [loan_amount, repayment_period])

  const onSubmit = (data) => {
    updateCurrentPage('LET_KNOW_YOU', { form_values: data});
  };

  const onChangeValue = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    // Allow digits and a single decimal point
    if (
      !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
      (event.key === "." && inputValue.includes(".")) // Block multiple periods
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Loan Request</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
           
            <div className="mb-3">
              <label htmlFor="loan_amount" className="font-[400] text-[14px]">
                Enter Loan Amount
              </label>
              <Controller
                name="loan_amount"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    min="10"
                    formatter={(value) =>
                      `NGN ${value?.replace(/\D/g, "") || ""}`
                    }
                    parser={(value) => value?.replace(/NGN\s?|[^\d]/g, "")}
                    defaultValue={getValues("loan_amount")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.loan_amount ? "error" : ""
                    }`}
                    status={errors.loan_amount ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("loan_amount");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.loan_amount && errors.loan_amount.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="max_repayment_period" className="font-[400] text-[14px]">
                Set Repayment Period
              </label>
              <Controller
                name="max_repayment_period"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    // min="10"
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/[^\d]/g, "")}
                    defaultValue={getValues("max_repayment_period")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.max_repayment_period ? "error" : ""
                    }`}
                    status={errors.max_repayment_period ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("max_repayment_period");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.max_repayment_period && errors.max_repayment_period.message}
              </small>
            </div>
            <div className="mb-3 flex gap-4">
              {
                duration_type?.map((item, index)=>(
                <Checkbox
                 key={item.name + "__duration" + index}
                  onChange={(e) =>
                    onChangeValue(item?.name, "duration_type")
                  }
                  checked={getValues("duration_type") === item?.name}
                  className={"capitalize"}
                  name={item?.name}
                >
                  {item?.name}
                </Checkbox>
                ))
              }
            </div>


            {/* <div className="my-10 flex justify-center w-3/5 mx-auto">
                <p className="bg-[#D9D9D9] rounded py-4 text-center w-full">{monthlyRepaymentAmount}</p>
            </div> */}
            <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default LoanRequest;
