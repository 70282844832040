import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import { Controller, useForm } from "react-hook-form";
import { useUserPermission } from "../../../../../../hooks/useUserPermission";
import { InputNumber } from "../../../../../common/form_inputs/Input";
import CustomButton from "../../../../../common/CustomButton";
import { Checkbox } from "../../../../../common/form_inputs/Checkbox";
import { TextArea } from "../../../../../common/form_inputs/TextArea";

const duration_type = [
  { name: "day"},
  { name: "week"},
  { name: "month"},
  { name: "year"},
]

const otherSettings = [
  { value: "request_guarantor", label: "Request Guarantor"},
  { value: "debit_guarantor", label: "Debit Guarantor in case of borrower’s default"},
  { value: "request_work_id", label: "Request Work ID"},
  { value: "verify_nin", label: "Verify NIN"},
]

const CreditRequirementForm = () => {
  const {
    openDrawer,
    data: { values },
  } = useDrawer();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      max_credit: values?.max_credit,
      min_age: values?.min_age,
      not_applicable: values?.not_applicable,
      duration_type: values?.duration_type,
      max_repayment_period: values?.max_repayment_period,
      additional_information: values?.additional_information,
      interest: values?.interest,
      request_guarantor: values?.request_guarantor,
      debit_guarantor: values?.debit_guarantor,
      request_work_id: values?.request_work_id,
      request_bank_statement: values?.request_bank_statement,
      verify_nin: values?.verify_nin,
      verify_bvn: values?.verify_bvn,
      Verify_phone: values?.Verify_phone,
      check_credit_bureau: values?.check_credit_bureau,
    },
    mode: "onBlur", // Validate on blur or change
    criteriaMode: "all",
  });

  const { user_permission } = useUserPermission();

  const onSubmit = (data) => {
    console.log(data);
    openDrawer("APPLICATION_SUMMARY", { values: { ...values, ...data } }, "CREDIT_REQUIREMENT_FORM");
  };

  const onChangeValue = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    // Allow digits and a single decimal point
    if (
      !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
      (event.key === "." && inputValue.includes(".")) // Block multiple periods
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Credit Requirement</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="interest" className="font-[400] text-[14px]">
                Set Interest
              </label>
              <Controller
                name="interest"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    min={0}
                    max={10}
                    step={0.1}
                    formatter={
                      (value) => `$ ${value?.replace(/[^\d.]/g, "") || ""}` // Allow decimals
                    }
                    parser={
                      (value) => value?.replace(/\$\s?|[^\d.]/g, "") // Allow decimals in parsing
                    }
                    defaultValue={getValues("interest")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      fieldState.error ? "error" : ""
                    }`}
                    status={fieldState.error ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("interest");
                    }}
                    onKeyPress={(e) => {
                      if (!/^\d*\.?\d*$/.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault(); // Allow only valid decimal input
                      }
                    }}
                  />
                )}
              />

              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.interest && errors.interest.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="max_credit" className="font-[400] text-[14px]">
                Set Maximum Credit Amount
              </label>
              <Controller
                name="max_credit"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    min="10"
                    formatter={(value) =>
                      `NGN ${value?.replace(/\D/g, "") || ""}`
                    }
                    parser={(value) => value?.replace(/NGN\s?|[^\d]/g, "")}
                    defaultValue={getValues("max_credit")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.max_credit ? "error" : ""
                    }`}
                    status={errors.max_credit ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("max_credit");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.max_credit && errors.max_credit.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="min_age" className="font-[400] text-[14px]">
                Set Minimum age of Applicant
              </label>
              <Controller
                name="min_age"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    min="10"
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/[^\d]/g, "")}
                    defaultValue={getValues("min_age")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.min_age ? "error" : ""
                    }`}
                    status={errors.min_age ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("min_age");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.min_age && errors.min_age.message}
              </small>
            </div>
            <div className="mb-3 flex gap-4">
              <Checkbox
                onChange={(e) =>
                  onChangeValue(e.target.checked, "not_applicable")
                }
                disabled={!user_permission?.edit_settings}
                checked={getValues("not_applicable")}
                className={"capitalize"}
              >
                Not Applicable
              </Checkbox>
            </div>
            <div className="mb-2">
              <label htmlFor="max_repayment_period" className="font-[400] text-[14px]">
                Set Maximum Repayment Period
              </label>
              <Controller
                name="max_repayment_period"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    // min="10"
                    formatter={(value) => `${value?.replace(/\D/g, "") || ""}`}
                    parser={(value) => value?.replace(/[^\d]/g, "")}
                    defaultValue={getValues("max_repayment_period")}
                    className={`text-[14px] ps-2 w-full text-[#033F4B] ${
                      errors.max_repayment_period ? "error" : ""
                    }`}
                    status={errors.max_repayment_period ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("max_repayment_period");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.max_repayment_period && errors.max_repayment_period.message}
              </small>
            </div>
            <div className="mb-3 flex gap-4">
              {
                duration_type?.map((item, index)=>(
                <Checkbox
                 key={item.name + "__duration" + index}
                  onChange={(e) =>
                    onChangeValue(item?.name, "duration_type")
                  }
                  checked={getValues("duration_type") === item?.name}
                  className={"capitalize"}
                  name={item?.name}
                >
                  {item?.name}
                </Checkbox>
                ))
              }
            </div>

            <div className="mt-10 mb-3 grid grid-cols-1 gap-x-6 gap-y-2 items-start">
                    {otherSettings?.map((item, index) => (
                      <Checkbox
                        onChange={(e) => onChangeValue(e.target.checked, item?.value)}
                        defaultChecked={getValues(item?.value)}
                        checked={getValues(item?.value)}
                        name={item?.value}
                        key={index + "other_settings"}
                        className={"capitalize"}
                      >
                        {item?.label}
                      </Checkbox>
                    ))}
            </div>
            <div className="mt-10 mb-5">
              <label htmlFor="first_name" className="font-[400] text-[14px]">
                Additional Information(optional)
              </label>
              <TextArea
                name="additional_information"
                defaultValue={getValues("additional_information")}
                placeholder="Enter Additional information"
                size={"large"}
                className={`text-[14px] ${errors.additional_information ? "error" : ""}`}
                disabled={!user_permission?.create_mandate}
                status={errors.additional_information ? "error" : ""}
                {...register("additional_information", {
                  required: "First name is required",
                })}
                onChange={(e) => onChangeValue(e, "additional_information")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.additional_information && errors.additional_information.message}
              </small>
            </div>
            <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                disabled={!user_permission?.create_mandate}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default CreditRequirementForm;