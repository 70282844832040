
import { Button, ConfigProvider } from "antd";
import { useState, useEffect } from "react";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useSendSchedule } from "../../../../api/mutation";
import CustomButton from "../../../common/CustomButton";
import { formatNumberOnly } from "../../../../utils/formatNumber";
import { Table } from "../../../common/form_inputs/Table";
import { formatDate } from "../../../../utils/formatDate";

export default function MandateSummary() {
  const {
    openDrawer,
    data: { mandate, formData },
  } = useDrawer();


  const [isLoading, setIsLoading] = useState(false);
  const mutation = useSendSchedule();

  const confirmRequest = () => {
    //I will send to backend from here


    // const formData = new FormData();
    // for (const key in formik.values) {
    //   if (key === "sc_file") {
    //     formData.append("file", formik.values[key]); // Append sc_file with key 'file'
    //   } else {
    //     formData.append(key, formik.values[key]);
    //   }
    // }

    formData.set("preview", "false");

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    // setIsLoading(true)
    // const data = response?.data;
    // setTimeout(()=>{
    //     setIsLoading(false)
    //     openDrawer("MANDATE_SUMMARY", {formik, mandate: data})
    // }, 1000)

    // setIsLoading(true)

    // mutation.mutate(formData);

    openDrawer("SET_PENAL_CHARGE", { formData }, "MANDATE_SUMMARY");
  };

  useEffect(() => {
    if (mutation.isPending) {
      setIsLoading(true);
    } else if (mutation.isError) {
      setIsLoading(false);
      const error = mutation?.error?.response?.data;

    } else if (mutation.isSuccess) {
      setIsLoading(false);
      const res = mutation?.data?.data?.data;

      openDrawer("SET_PENAL_CHARGE", { mandate });
    }
  }, [mandate, mutation?.data?.data?.data, mutation?.error?.response?.data, mutation.isError, mutation.isPending, mutation.isSuccess, mutation.status, openDrawer]);

  const columns = [
    {
      title: "Due Date",
      dataIndex: "start_date",
      render:(text)=>(
        formatDate(text)
      )
    },
    {
      title: "Principal Payment",
      dataIndex: "amount",
      textAlign: "center"
    },
    {
      title: "Interest Payment",
      dataIndex: "interest",
      
    },
    {
      title: "Total Amount Due",
      dataIndex: "total_amount_due",
    },
  ];


  const dataSource = mandate?.schedules?.map((data, i) => ({...data, key: data?.id+i}));


  return (
    <>
      <div className="w-full px-1 py-4 rounded-lg bg-white shadow-sm">
        <h4 className="font-[600] text-[18px] mb-2">Loan Repayment Schedule</h4>
        <div className="flex flex-col gap-y-1">
          <p className="font-[500] text-[16px]">
            {mandate?.first_name} {mandate?.last_name}
          </p>
          <div className="grid grid-cols-2 lg:gap-x-8 md:gap-x--8 gap-x-4 gap-y-1">
            <p className="font-[500] text-[14px]">
              BVN <span className="font-[300]">{mandate?.bvn}</span>
            </p>
            {/* <p className="font-[500] text-[14px]">
              Date <span className="font-[300]">{mandate?.start_date}</span>
            </p> */}
            <p className="font-[500] text-[14px]">
              Phone <span className="font-[300]">{mandate?.phone}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Email <span className="font-[300]">{mandate?.email}</span>
            </p>
          </div>

          <div className="flex flex-col gap-y-1 flex-wra mt-4">
            <p className="font-[500] text-[14px]">
              Total Repayable Amount:{" "}
              <span className="font-[300]">NGN {formatNumberOnly(mandate?.total_amount)}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Duration:{" "}
              <span className="font-[300]">
                {mandate?.tenor} {mandate?.payment_type}
              </span>
            </p>
            {/* <p className="font-[500] text-[14px]">
              Total Interest: <span className="font-[300]">{mandate?.total_interest}</span>
            </p> */}
          </div>
          <div className="mt-3">
            <ConfigProvider
              theme={{
                token: {
                  headerBg: "#3F3E6D",
                  headerColor: "#FFF",
                  headerBorder: "none",
                },
              }}
            >
              <Table
                  header_bg={true}
                columns={columns}
                pagination={false}
                dataSource={dataSource}
                size="small"
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="mt-5 flex justify-center items-center">
          <CustomButton onClick={confirmRequest} loading={isLoading}>
            Confirm mandate request
          </CustomButton>
          {/* <Button
                    size="large"
                    shape={"round"}
                    loading={false}
                    className="rounded-[8px] bg-[#8B0404] text-[#FFF] text-[18px]  hover:!bg-[#8B0404] hover:!text-[#FFF]"
                  >
                    Cancel
                  </Button> */}
        </div>
      </div>
    </>
  );
}

