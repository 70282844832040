

import React, { useState } from 'react';
import { Table } from "../../form_inputs/Table";
import moment from "moment";
import { IoCopy, IoCopyOutline, IoEyeOutline } from "react-icons/io5";
import { formatNumberOnly } from '../../../../utils/formatNumber';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import { decryptData } from '../../../../utils/cryptData';



const TransactionsTable = ({rows, handleOpen}) => {

  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    text: "Copy url",
    index: null
  });


  const handleCopy = (val, index) => {
    copyToClipboard(val).then((res) => {
      if(res.status) setCopy({ icon: IoCopy, state: true, text: "Copied", index });
    })
};




  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text, row) => (
          <p className="flex flex-col">{moment(decryptData(text) ?? "").format("DD. MMM. YYYY")} 
           <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(decryptData(row?.createdAt)).format('h:mm:ss a')}
          </small>
          </p>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text, row) => (
          <p className="">NGN {formatNumberOnly(decryptData(text) ?? 0)}</p>
    
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'email',
      render: (text, row)=>(
        <>
          {decryptData(row?.schedule?.mandate?.email) ?? "N/A"}
        </>
      )
    },
    {
      title: 'Transaction Reference',
      dataIndex: 'reference',
      render: (text, row, index)=>(
        <div className='flex gap-2 items-center'>
          {text ?? "N/A"}
            <span onClick={()=>handleCopy(text ?? "",  index)} className='cursor-pointer'>
                {
                    copy?.state && copy?.index === index?
                    <IoCopy color="#733534" size={"2.5vh"} />
                    :
                    <IoCopyOutline color="#733534" size={"2.5vh"} />
                }
            </span>
        </div>
      )
    },
    {
      // title: <p className='text-gray-700 font-medium'>Payment Method</p>,
      title: "Payment Method",
      dataIndex: 'payment_type',
      render: (text)=>(
        <p className='capitalize'>{(decryptData(text))?.replace('_', ' ') ?? "N/A"}</p>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <div className={`capitalize flex gap-x-1 items-center max-w-full ${decryptData(text)==="pending"? "bg-yellow-100 text-yellow-900" : (decryptData(text)==="successful" || decryptData(text)==="success")? "bg-green-100 text-green-900": "bg-red-100 text-red-500" } rounded-full px-2 text-center uppercase font-[500]`}>
            <p className={`h-2 w-2 rounded-full ${decryptData(text)==="pending"? "bg-yellow-700" : (decryptData(text)==="successful" || decryptData(text)==="success")? "bg-green-700": "bg-red-700"}`}></p>
            <i>{decryptData(text)}</i></div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' 
          onClick={()=>handleOpen(row)}
          >
            <IoEyeOutline size={"18px"}/>
          </small>
        </>
      )
    },
  ];


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));





  return (
    <>
        <Table onRow={(row)=>(
          {
            onClick:()=>handleOpen(row)
          }
        )} columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} pagination={false} striped={true}/>
    
    </>
  );
};
export default TransactionsTable;