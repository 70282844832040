import { useGetProfile } from "../api/getHooks";

export const useProfileData=()=>{
    const {data, error} = useGetProfile();




    if(!error){
        const responseData = data?.data?.data

        return {status: true, data: responseData, isAdmin: responseData?.role==="supper_admin"}
    }
    else if(error){
        const msg = error?.response?.data?.message


        return {status: false, msg}
    }
}