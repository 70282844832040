

import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Table } from "../../form_inputs/Table";
import { Button } from "antd";
import { useCallback } from "react";
import { useStaffPosition } from "../../../../hooks/useStaffPositions";
import { useUserPermission } from '../../../../hooks/useUserPermission';
import { decryptData } from "../../../../utils/cryptData";



const TeamTable = ({rows, handleOpen}) => {

  const { data } = useStaffPosition();

  const { user_permission } = useUserPermission();



  const selectRole = useCallback((id)=>{
    return data?.find((item)=>item?.id===id)?.name
  }, [])


  


  const columns = [
    {
      title: 'FULL NAME',
      dataIndex: 'name',
      render: (text, row) => (
          <p className="">
            {decryptData(row?.first_name)} {decryptData(row?.last_name)}
          </p>
      ),
    },
   
    {
      title: 'CUSTOMER EMAIL',
      dataIndex: 'email',
      render: (text, row)=>(
        <>
          {decryptData(text) ?? "N/A"}
        </>
      )
    },
    {
        title: 'PHONE NUMBER',
        dataIndex: 'phone',
        render: (text, row) => (
            <p className="">{decryptData(text)}</p>
      
        ),
      },
    {
        title: 'ROLE',
        dataIndex: 'role',
        render: (text, row) => (
            <p className="">{selectRole(decryptData(row?.staffTypeId))}</p>
      
        ),
      },
    
    {
      title: 'ACTION',
      dataIndex: 'action',
      render: (text, row) => (
        <div className={`flex gap-x-2`}>
                <Button type={"text"} disabled={!user_permission?.edit_settings} className='cursor-pointer p-1'>
                    <CiEdit className='text-[#9DA0AE]' size={"3vh"} onClick={()=>handleOpen('edit', row)}/>
                </Button>
                <Button type={"text"} disabled={!user_permission?.edit_settings} className='cursor-pointer p-1'>
                    <RiDeleteBin6Line className='text-[#9DA0AE]' size={"3vh"} onClick={()=>handleOpen('delete', row)}/>
                </Button>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action2',
      render: (text, row)=>(
        <>
        {
            row?.resend_link && (
                <p className='flex justify-center items-center py-[3px] px-[5px] bg-green-100 text-green-500 border border-green-500 rounded-[5px]'>Resend Link</p>
            )
        }
        </>
      )
    },
  ];


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));





  return (
    <>
            <Table columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }}/>

    
    </>
  );
};
export default TeamTable;