import React from 'react'
import ModalBox from '../ModalBox'
import { useModal } from '../../../hooks/zustand_hooks/useModal';
import WhatToDo from '../../specific/admin_side_loan/dashboardTask/WhatToDo';

const GeneralModalBox = () => {

//========================== use modal hook ========================
    const { isOpen, title, closeModal } = useModal();

  return (
    <>
        <ModalBox open={isOpen} title={title} handleClose={closeModal} footer={null}>
            <hr />
            <div className='my-4'>
                <WhatToDo />
            </div>
        </ModalBox>
    </>
  )
}

export default GeneralModalBox