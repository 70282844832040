import { Input, Result } from "antd";
import {
  useGetMandate,
} from "../../../api/admin_mutation";
import LoanWithDrawers from "../../../components/common/drawer/LoanWithDrawers";
import MandateStatusTable from "../../../components/specific/admin_side_loan/MandateStatusTable";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { exportExcel } from "../../../utils/exportExcel";
import { Select } from "../../../components/common/form_inputs/Select";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import Pagination from "../../../components/common/form_inputs/Pagination";
import { useProfileData } from "../../../hooks/useProfileData";
import { Spin } from "../../../components/common/form_inputs/Spin";
import CustomButton from "../../../components/common/CustomButton";
import { decryptData } from "../../../utils/cryptData";

const { Search } = Input;

export default function MandateStatus({due_loan}) {


  const { isAdmin } = useProfileData()


  const { openDrawer, updateDrawerTitle } = useDrawer();



    const [currentData, setCurrentData] = useState([]);

    const [filterBy, setFilterBy] = useState("")


  const { mutate: mutateGetMandate, data, isPending, isError } = useGetMandate(isAdmin, due_loan);


  const mandateStatus = useMemo(() => [
    "active",
    "part debited",
    "completed",
    "canceled",
    "confirmed",
    "reschedule pending",
    "reschedule approved",
    "reschedule declined",
  ], []);

  useEffect(() => {

    const due_payload = {
      start_date: moment().startOf("month").format("YYYY/MM/DD"),
      end_date: moment().endOf("month").format("YYYY/MM/DD"),
      page: 1
    }
    
    mutateGetMandate(
      due_loan ? due_payload : {
      status: mandateStatus,
    });
  }, [due_loan, mutateGetMandate, mandateStatus]);

  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    let startDate;
    let endDate;
    if(value==='next_month'){
      startDate = moment().add(1, 'month').startOf('month').format("YYYY/MM/DD");
      endDate = moment().add(1, 'month').endOf('month').format("YYYY/MM/DD");
    }
    else if(value==='next_year'){
      startDate = moment().add(1, 'year').startOf('year').format("YYYY/MM/DD");
      endDate = moment().add(1, 'year').endOf('year').format("YYYY/MM/DD");
    }
    else if(value==='last_month'){
        startDate = moment().subtract(1, 'month').startOf('month').format("YYYY/MM/DD");
        endDate = moment().subtract(1, 'month').endOf('month').format("YYYY/MM/DD");
    }
    else if(value==='last_year'){
      startDate = moment().subtract(1, 'year').startOf('year').format("YYYY/MM/DD");
      endDate = moment().subtract(1, 'year').endOf('year').format("YYYY/MM/DD");
    }
    else{
        startDate = moment().startOf(value).format("YYYY/MM/DD");
        endDate = moment().endOf(value).format("YYYY/MM/DD");
    }
    // const startDate = moment().startOf(value).format("YYYY/MM/DD");
    // const endDate = moment().endOf(value).format("YYYY/MM/DD");

    mutateGetMandate({
      start_date: startDate,
      end_date: endDate,
      status: mandateStatus,
    });

  };


  const response = data?.data;

    useEffect(() => {
    if (!isPending && response?.data) {
      // Only update the currentData once the loading is done and new data has arrived
      setCurrentData(response?.data);
    }
  }, [isPending, response]);

  const handleOpenDrawer = (data) => {
    openDrawer("LOAN_DETAIL", { mandate: data });
    updateDrawerTitle("Mandate");
  };


  





  const tableData = useMemo(()=>{
    return response?.data
  }, [response])


  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);


  useEffect(() => {
    const filterData = () => {
      if (searchValue?.trim()) {
        const value = searchValue?.toLowerCase();
        const updatedData = tableData?.filter((item) => {
          const customerMatch = [
            item?.first_name?.toLowerCase(),
            item?.last_name?.toLowerCase(),
          ]
          const businessMatches = [
            item?.business_name?.toLowerCase()
          ]

          const filterMatch = filterBy==='customer'?customerMatch:businessMatches

          const matches = filterMatch.some((field) => field?.includes(value));
          return matches;
        });

        setFilteredData(updatedData?.length ? updatedData : []);
      } else {
        // If search is cleared, show full data
        setFilteredData(tableData);
      }
    };

    filterData();
  }, [searchValue, tableData, filterBy]);



  const tableRows = searchValue?.length ? filteredData : currentData || []


  const handlePaginateChange = (page) => {
    mutateGetMandate({
      page,
      status: mandateStatus,
    });
  };  
  
  
  
  const exportAs = (value) => {
    exportExcel({
      excelData: tableRows,
      fileName: `all mandates`,
    });
  };






  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">Mandate</h3>
        {isAdmin && (
          <div className="mt-3 mb-2">
            <Select
              placeholder="Filter by business"
              size="small"
              optionFilterProp="label"
              onChange={(value)=>setFilterBy(value)}
              options={[
                {
                  value: "customer",
                  label: "Customer",
                },
                {
                  value: "business",
                  label: "Business",
                }
              ]}
            />
          </div>
        )}
        <div className="flex justify-between mb-3 gap-3 flex-wrap">
          <div>
            <Search
              placeholder="Search Name"
              // onSearch={onSearch}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{
                width: 200,
              }}
            />
          </div>
          <div className="flex gap-x-2">
            <Select
              size="small"
              defaultValue={due_loan?"this month" : "all_time"}
              style={{ width: 120 }}
              onChange={handleChange}
              options={
                due_loan?[
                  { value: "month", label: "This Month" },
                  { value: "next_month", label: "Next Month" },
                  { value: "last_month", label: "Last Month" },
                  { value: "next_year", label: "Next Year" },
                  { value: "last_year", label: "Last Year" },
                ]: [
                { value: "all_time", label: "All time" },
                { value: "today", label: "Today" },
                { value: "week", label: "This week" },
                { value: "month", label: "This month" },
                { value: "year", label: "This year" },
              ]}
            />
            <CustomButton onClick={exportAs} size={"small"}>Export as Excel</CustomButton>
          </div>
        </div>
        {
        isPending && !tableRows?.length ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ):
        isError ? (
          <Result
            status="error"
            title="Unexpected error occurred"
            extra={null}
          />
        ) : (
          <div className="relative">
         
          <MandateStatusTable
            rows={tableRows}
            handleOpen={handleOpenDrawer}
          />
          {isPending && (
            <div className="flex justify-center z-10">
              <Spin />
            </div>
          )}
          <div className="mt-2">
            <Pagination
              current={response?.currentPage}
              align={"end"}
              total={response?.totalRecords}
              onChange={handlePaginateChange}
            />
          </div>
        </div>
         
        )}
      </main>

      <LoanWithDrawers />
    </>
  );
}
