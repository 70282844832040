import { formatNumberOnly } from "../../../../utils/formatNumber";
import { ArrowIcon } from "../../../../utils/svg_icons";
import { CiEdit } from "react-icons/ci";
import CustomButton from "../../../common/CustomButton";

const LoanApplicationSummary = () => {
  return (
    <>
      <main>
        <h3 className="font-[500] text-[16px]">Loan Summary</h3>
        <div className="my-5 flex gap-x-8 flex-wrap items-end">
          <div className="flex flex-wrap gap-3 items-center">
            <div className="h-32 w-48 p-4 bg-[#6B689C4F] rounded flex flex-col gap-y-3">
              <p className="font-[275] text-[14px] leading-[18px]">
                Loan Amount
              </p>
              <p>NGN {formatNumberOnly(40000)}</p>
              <p className="font-[275] text-[14px] leading-[18px]">
                Salary Advance
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-[275] text-[14px] leading-[18px]">
                3% monthly
              </p>
              <ArrowIcon width={"70"} height={"20"} />
            </div>
            <div className="h-32 w-48 p-4 bg-[#6B689C4F] rounded flex flex-col gap-y-3">
              <p className="font-[275] text-[14px] leading-[18px]">Repayment</p>
              <p>NGN {formatNumberOnly(45000)}</p>
              <p className="font-[275] text-[14px] leading-[18px]">4 month</p>
            </div>
          </div>
          <div>
            <p className="text-[#901414] cursor-pointer flex items-center">
              Edit
              <CiEdit className={"text-[#901414]"} size={20} />
            </p>
          </div>
        </div>
        <div className="mt-6">
              <CustomButton
                className={"w-full md:w-3/5"}
                type="submit"
              >
                Continue
              </CustomButton>
            </div>
      </main>
    </>
  );
};

export default LoanApplicationSummary;
