

import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // Securely load this key

// Decryption function
export const decryptData=(encryptedText)=>{
    
    if (typeof encryptedText !== 'string' || !encryptedText?.startsWith("ENC_")) {
        return encryptedText; // Already decrypted
    }

    try {
        // Separate the IV and encrypted data
        const [ivHex, encrypted] = encryptedText?.slice(4)?.split(':');
        const iv = CryptoJS.enc.Hex.parse(ivHex);
        const key = CryptoJS.enc.Hex.parse(SECRET_KEY);

        // Decrypt with AES using the same key and IV
        const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return decrypted?.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
}





// export const encryptData = (data) => {
//     const ciphertext = CryptoJS.AES.encrypt(data, crypto_key).toString();
//     return ciphertext;
// };

// export const decryptData = (ciphertext) => {
//     const bytes = CryptoJS.AES.decrypt(ciphertext, crypto_key);
//     const originalText = bytes.toString(CryptoJS.enc.Utf8);
//     return originalText;
// };