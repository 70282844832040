import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { VerifiedIcon } from "../../../../../../utils/svg_icons";

const RenderSection = ({
  title,
  field_names,
  data,
  coming_soon,
  require_verify,
}) => {
  return (
    <>
      <main>
        <section className="w-full md:max-w-md mx-auto">
          <h3 className="text-center font-[400] text-[16px] text-[#4F4F4F] flex justify-center gap-x-10">
            {title}
            {coming_soon && (
              <small className="text-red-500 ">Coming soon!</small>
            )}
          </h3>
        </section>
        <hr className="h-2" />
        <section className="my-5 w-full md:max-w-md mx-auto">
          <div className="flex flex-col gap-y-3">
            {field_names?.map((item, index) => (
              <div key={item?.value + index}>
                {item?.link ? (
                  <p className="text-center cursor-pointer underline text-[#733534] font-[300] text-[14px]">
                    {item?.label}
                  </p>
                ) : (
                  <div className="flex justify-between md:flex-nowrap flex-wrap">
                    <h4 className="font-[400] text-[14px]">{item?.label}:</h4>
                    <div className="flex gap-x-5">
                      <p className="font-[300]">
                        {data?.[item?.value] ?? "N/A"}
                      </p>
                      <p
                        onClick={() => item?.action(item?.value)}
                        className="text-center cursor-pointer underline text-[#733534] font-[300] text-[13px] flex items-center gap-x-2"
                      >
                        {item?.action_name}
                        {item?.verified && (
                          <small className="text-red-500">
                            <VerifiedIcon />
                          </small>
                        )}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {require_verify && (
            <div className="flex justify-center gap-x-8 mt-5">
              <Button
                type="solid"
                className="bg-green-500 text-white hover:bg-green-500 hover:text-white disabled:bg-[#6E8769]"
                disabled={coming_soon}
              >
                Verified
              </Button>
              <Button
                type="solid"
                className="bg-[#733534] text-white hover:bg-[#733534] hover:text-white disabled:bg-[#8E6565]"
                disabled={coming_soon}
              >
                Not Correcct
              </Button>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default RenderSection;

RenderSection.propTypes = {
  title: PropTypes.string,
  field_names: PropTypes.array,
  data: PropTypes.object,
  coming_soon: PropTypes.bool,
  require_verify: PropTypes.bool,
};
