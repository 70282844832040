import { useParams } from "react-router-dom";
import LoanSummary from "./LoanSummary";
import BVN_OTP from "./BVN_OTP";
import BankAccounts from "./BankAccounts";
import SetMandate from "./SetMandate";
import { useUserLoanApproval } from "../../../hooks/zustand_hooks/useUserLoanApproval";
import BorrowerLayout from "../../../components/layouts/borrower_layout/BorrowerLayout";
import { useGetUserMandate } from "../../../api/getHooks";
import { useEffect } from "react";
import { Result } from "antd";

export default function UserLoanApprovalPages() {
  const params = useParams();
  const orderID = params.orderId;

  const { currentPage, updateCurrentPage, data: {status} } = useUserLoanApproval();

  const { data } = useGetUserMandate(orderID);
  const response = data?.data;

  const schedules = response?.data;

  const rescheduled = schedules?.status === "reschedule pending";

  useEffect(() => {
    if (
      !rescheduled &&
      schedules?.accounts?.length &&
      currentPage !== "SET_MANDATE"
    ) {
      updateCurrentPage("BANK_ACCOUNTS", {
        bank_accounts: schedules?.accounts,
      });
    }
  }, [currentPage, rescheduled, schedules, updateCurrentPage]);



  return (
    <>
      <BorrowerLayout
        customer_name={`${schedules?.first_name} ${schedules?.last_name}`}
        company={schedules?.company}
        awaitAuthorization={rescheduled}
      >
        {currentPage === "LOAN_SUMMARY" ? (
          <LoanSummary
            mandate={schedules}
            orderID={orderID}
            awaitAuthorization={rescheduled}
          />
        ) : currentPage === "BVN_OTP" ? (
          <BVN_OTP mandateID={orderID} />
        ) : currentPage === "BANK_ACCOUNTS" ? (
          <BankAccounts />
        ) : currentPage === "SET_MANDATE" ? (
          <SetMandate company={schedules?.company} />
        ) : currentPage === "RESPONSE_VIEW" ? (
          <div className="mt-10">
            <Result
              status={status}
              title={
                <p className="font-[500] text-[24px] text-[#2A2A29]">
                  You have approved the new loan schedule
                </p>
              }
              extra={null}
            />
          </div>
        ) : null}
      </BorrowerLayout>
    </>
  );
}
