import React from "react";
import avatar from "../../../../../assets/avatar.png";
import { Image } from "antd";
import { useForm } from "react-hook-form";
import { Input } from "../../../../common/form_inputs/Input";
import CustomButton from "../../../../common/CustomButton";
import { Select } from "../../../../common/form_inputs/Select";
import { useCustomerLoanApplication } from "../../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const WorkInformation = () => {
  const { updateCurrentPage, data: { form_values } } = useCustomerLoanApplication();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    updateCurrentPage("KYC_DETAIL_SUMMARY", { form_values: { ...form_values, ...data }} );
  };

  const onChangeValue = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName);
  };

  return (
    <>
      <main className="flex flex-col gap-y-7 my-5">
        <h3 className="font-[500] text-[16px] text-center">Work Information</h3>
        <div>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                Occupation
              </label>
              <Input
                name="occupation"
                defaultValue={getValues("occupation")}
                placeholder="e.g. Accountant"
                size={"large"}
                className={`text-[14px] ${errors.occupation ? "error" : ""}`}
                status={errors.occupation ? "error" : ""}
                {...register("occupation", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "occupation")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.occupation && errors.occupation.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="work_email" className="font-[400] text-[14px]">
                Work Email
              </label>
              <Input
                name="work_email"
                defaultValue={getValues("work_email")}
                placeholder="e.g. Johndoe@work.com"
                size={"large"}
                className={`text-[14px] ${errors.work_email ? "error" : ""}`}
                status={errors.work_email ? "error" : ""}
                {...register("work_email", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "work_email")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.work_email && errors.work_email.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="salary" className="font-[400] text-[14px]">
                Monthly Salary
              </label>
              <Input
                name="salary"
                defaultValue={getValues("salary")}
                placeholder="e.g 40,000"
                size={"large"}
                className={`text-[14px] ${errors.salary ? "error" : ""}`}
                status={errors.salary ? "error" : ""}
                {...register("salary", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "salary")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.salary && errors.salary.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="employer" className="font-[400] text-[14px]">
                Employer
              </label>
              <Input
                name="employer"
                defaultValue={getValues("employer")}
                placeholder="Enter Place of work"
                size={"large"}
                className={`text-[14px] ${errors.employer ? "error" : ""}`}
                status={errors.employer ? "error" : ""}
                {...register("employer", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "employer")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.employer && errors.employer.message}
              </small>
            </div>
            <div className="mb-2">
              <label
                htmlFor="employer_address"
                className="font-[400] text-[14px]"
              >
                Employer's Address
              </label>
              <Input
                name="employer_address"
                defaultValue={getValues("employer_address")}
                placeholder="e.g. 2, ola street"
                size={"large"}
                className={`text-[14px] ${
                  errors.employer_address ? "error" : ""
                }`}
                status={errors.employer_address ? "error" : ""}
                {...register("employer_address", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "employer_address")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.employer_address && errors.employer_address.message}
              </small>
            </div>
            <div className="mb-2">
              <label htmlFor="city" className="font-[400] text-[14px]">
                City
              </label>
              <Input
                name="city"
                defaultValue={getValues("city")}
                placeholder="e.g Ikeja"
                size={"large"}
                className={`text-[14px] ${errors.city ? "error" : ""}`}
                status={errors.city ? "error" : ""}
                {...register("city", {
                  required: false,
                })}
                onChange={(e) => onChangeValue(e, "city")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.city && errors.city.message}
              </small>
            </div>

            <div className="mb-2">
              <label htmlFor="" className="font-[400] text-[14px]">
                State
              </label>
              <Select
                defaultValue={getValues("state")}
                placeholder="Select state"
                className="w-full"
                optionFilterProp="label"
                status={errors.state ? "error" : ""}
                {...register("state", {
                  required: "state is required",
                })}
                onChange={(value) => {
                  setValue("state", value);
                  trigger("state");
                }}
                options={[
                  { value: "lagos", label: "Lagos" },
                  { value: "kaduna", label: "Kaduna" },
                ]}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.state && errors.state.message}
              </small>
            </div>

            <div className="mt-7 mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                // loading={isLoading}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default WorkInformation;
