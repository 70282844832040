import LoanApplicationLayout from "../../../components/layouts/loan_application_layout/LoanApplicationLayout";
import LoanRequest from "../../../components/specific/customer_loan_application/custom_form/LoanRequest";
import LetKnowYou from "../../../components/specific/customer_loan_application/custom_form/LetKnowYou";
import CompleteKyc from "../../../components/specific/customer_loan_application/custom_form/CompleteKyc";
import VerificationOtp from "../../../components/specific/customer_loan_application/custom_form/VerificationOtp";
import ShareBankStatement from "../../../components/specific/customer_loan_application/custom_form/ShareBankStatement";
import KycSubmitted from "../../../components/specific/customer_loan_application/custom_form/KycSubmitted";
import KycDetailSummary from "../../../components/specific/customer_loan_application/custom_form/KycDetailSummary";
import LoanApplicationSummary from "../../../components/specific/customer_loan_application/custom_form/LoanApplicationSummary";
import NewUserForm from "../../../components/specific/customer_loan_application/custom_form/NewUserForm";
import LoanApplicationLinkCreated from "../../../components/specific/customer_loan_application/custom_form/LoanApplicationLinkCreated";
import { useCustomerLoanApplication } from "../../../hooks/zustand_hooks/useCustomerLoanApplication";

const page_section = {
  LOAN_REQUEST: "Complete your loan form here",
  LET_KNOW_YOU:
    "Loans are insurance, you are always covered as long as you pay back.",
  COMPLETE_KYC:
    "Loans are insurance, you are always covered as long as you pay back.",
};

const CustomApplication = () => {

  const { currentPage } = useCustomerLoanApplication();


  

  return (
    <>
      <LoanApplicationLayout description={page_section?.[currentPage]}>
        {currentPage === "LOAN_REQUEST" ? (
          <LoanRequest />
        ) : currentPage === "LET_KNOW_YOU" ? (
          <LetKnowYou />
        ) : currentPage === "NEW_USER_FORM" ? (
          <NewUserForm />
        ) : currentPage === "APPLICATION_LINK_CREATED" ? (
          <LoanApplicationLinkCreated />
        )
        
        : currentPage === "COMPLETE_KYC" ? (
          <CompleteKyc />
        ) : currentPage === "KYC_DETAIL_SUMMARY" ? (
            <KycDetailSummary />
        ) : currentPage === "VERIFY_OTP" ? (
          <VerificationOtp />
        ) : currentPage === "SHARE_BANK_STATEMENT" ? (
          <ShareBankStatement />
        ) : currentPage === "KYC_COMPLETED" ? (
          <KycSubmitted />
        ) : currentPage === "LOAN_APPLICATION_SUMMARY" ? (
          <LoanApplicationSummary />
        ) : null}
      </LoanApplicationLayout>
    </>
  );
};

export default CustomApplication;
