import React, { useEffect, useState } from "react";
import { Input } from "../../../common/form_inputs/Input";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { Select } from "../../../common/form_inputs/Select";
import { useAddStaff } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useStaffPosition } from "../../../../hooks/useStaffPositions";

export const InviteMember = () => {
  //external hooks
  const { closeDrawer, data: { staff_detail} } = useDrawer();



  const addStaffMutation = useAddStaff(staff_detail? "edit": null, staff_detail?.id);

  const { showMessage, contextHolder } = useCustomMessage();

  const { data } = useStaffPosition();

  //

  //react hooks state
  const [isLoading, setIsLoading] = useState(false);

  //

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
      staffTypeId: "",
      phone: "",
    },
  });


  useEffect(() => {
    if (staff_detail) {
      reset({
        first_name: staff_detail.first_name ?? "",
        last_name: staff_detail.last_name ?? "",
        email: staff_detail.email ?? "",
        phone: staff_detail.phone ?? "",
        staffTypeId: staff_detail.staffTypeId ?? "",
      });
    }
  }, [staff_detail, reset]);

  const onsubmit = (data) => {
    const json = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone: data?.phone,
      email: data?.email,
      staffTypeId: data?.staffTypeId,
      profile_picture: "",
    };

    setIsLoading(true);

    addStaffMutation.mutate(json, {
      onError: (error) => {
        console.log(error);
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        reset();

        showMessage("successs", res?.message);
        closeDrawer();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
    // closeDrawer()
  };

  const handleChange = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName);
  };

  const options = data?.map((item) => {
    return {
      ...item,
      value: item?.id,
      label: item?.name,
    };
  });

  return (
    <>
      <main className="w-full max-w-lg mx-auto">
        <p className="my-5 font-[500] text-[16px] text-black capitalize">
          Provide new member Information
        </p>
        <form action="" onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-3">
            <label htmlFor="first_name" className="font-[400] text-[16px]">
              First Name
            </label>
            <Input
              name="first_name"
              defaultValue={getValues("first_name")}
              placeholder="Enter First Name"
              size={"large"}
              className={` text-[14px] ${
                errors.first_name ? "error" : ""
              }`}
              status={errors.first_name ? "error" : ""}
              {...register("first_name", {
                required: "First name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "first_name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.first_name && errors.first_name.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="last_name" className="font-[400] text-[16px]">
              Last Name
            </label>
            <Input
              name="last_name"
              defaultValue={getValues("last_name")}
              placeholder="Enter Last Name"
              size={"large"}
              className={` text-[14px] ${
                errors.last_name ? "error" : ""
              }`}
              status={errors.last_name ? "error" : ""}
              {...register("last_name", {
                required: "Last name is required",
              })}
              onChange={(e) => handleChange(e.target.value, "last_name")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.last_name && errors.last_name.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="font-[400] text-[16px]">
              Email Address
            </label>
            <Input
              name="email"
              defaultValue={getValues("email")}
              type="email"
              placeholder="Enter Email"
              size={"large"}
              className={` text-[14px] ${errors.email ? "error" : ""}`}
              status={errors.email ? "error" : ""}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
              onChange={(e) => handleChange(e.target.value, "email")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.email && errors.email.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="font-[400] text-[16px]">
              Phone Number
            </label>
            <Input
              name="phone"
              defaultValue={getValues("phone")}
              type="number"
              placeholder="Enter Phone number"
              size={"large"}
              className={` text-[14px] ${errors.phone ? "error" : ""}`}
              status={errors.phone ? "error" : ""}
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^0\d{10}$/,
                  message:
                    "Phone number must start with 0 and be 11 digits long",
                },
              })}
              onChange={(e) => handleChange(e.target.value, "phone")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.phone && errors.phone.message}
            </small>
          </div>

          <div className="mb-3 flex flex-col">
            <label htmlFor="" className="font-[400] text-[16px]">
              Role
            </label>
            <Select
              name="staffTypeId"
              placeholder="Select Role"
              defaultValue={getValues("staffTypeId")}
              {...register("staffTypeId", {
                required: "Role is required",
              })}
              onChange={(value) => handleChange(value, "staffTypeId")}
              options={options}
              className={"h-[42px]"}
              status={errors.staffTypeId ? "error" : ""}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.staffTypeId && errors.staffTypeId.message}
            </small>
          </div>

          <div className="mt-10 mb-3">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={isLoading}
            >
              {
                staff_detail? "Update Member": "Add Member"
              }
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
};
