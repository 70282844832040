import React, { useEffect, useMemo, useState } from "react";
import { formatNumberOnly } from "../../../../utils/formatNumber";
import { Select } from "../../../common/form_inputs/Select";
import BusinessUserTable from "../../../common/table/business_user_table/BusinessUserTable";
import Search from "antd/es/input/Search";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useGetCompanyStaff } from "../../../../api/getHooks";
import {
  useGetStatistics,
  useTopupWallet,
} from "../../../../api/admin_mutation";
import moment from "moment";
import { Result, Popover, Button } from "antd";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/form_inputs/Input";
import CustomButton from "../../../common/CustomButton";
import { useProfileData } from "../../../../hooks/useProfileData";
import { Spin } from "../../../common/form_inputs/Spin";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

const BusinessDetailView = () => {
  const { isAdmin } = useProfileData();

  const {
    openDrawer,
    updateDrawerTitle,
    data: { company },
  } = useDrawer();

  const { showMessage, contextHolder } = useCustomMessage();

  const { data, isLoading, isError } = useGetCompanyStaff(company?.id);

  const customers = data?.data?.data;

  const { mutate, data: statData } = useGetStatistics(true);
  useEffect(() => {
    mutate({
      companyId: company?.id,
    });
  }, [company?.id, mutate]);

  const statisticData = useMemo(() => {
    return statData?.data?.data;
  }, [statData]);

  const statisticsItems = [
    {
      name: "Mandate Request count",
      value: statisticData?.total_mandates ?? 0,
    },
    {
      name: "Active Mandate count",
      value: statisticData?.total_authorized ?? 0,
    },

    {
      name: "Wallet",
      value: "NGN " + formatNumberOnly(statisticData?.wallet_balance ?? 0),
    },
    {
      name: "Total Credit approved",
      value: "NGN " + formatNumberOnly(statisticData?.total_amount ?? 0),
    },
    {
      name: "Total Repayment",
      value: "NGN " + formatNumberOnly(statisticData?.total_paid ?? 0),
    },
    {
      name: "Subscription Plan",
      value: company?.subscriptions?.[0]?.plan?.name ?? "No plan",
    },
  ];

  // const setUsage = () => {
  //   openDrawer("SET_USAGE", "", "BUSINESS_DETAIL");
  // };

  const viewTimeline = () => {
    openDrawer("COMPANY_TIMELINE", {}, "BUSINESS_DETAIL");
  };
  const handleOpen = () => {};

  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    const startDate = moment().startOf(value).format("YYYY/MM/DD");
    const endDate = moment().endOf(value).format("YYYY/MM/DD");

    mutate({
      start_date: startDate,
      end_date: endDate,
      companyId: company?.id,
    });
  };

  //======================= Topup Wallet ========================

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    reset,
    getValues,
  } = useForm({});

  const mutateTopUpWallet = useTopupWallet();

  const [openPopover, setOpenPopover] = useState(false);

  const onSubmitTopup = (data) => {
    const json = {
      companyId: company?.id,
      amount: data?.amount,
    };

    mutateTopUpWallet.mutate(json, {
      onSuccess: (data) => {
        reset();
        setOpenPopover(false);
        showMessage("success", data?.data.message);
        mutate({
          companyId: company?.id,
        });
      },
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
    });
  };

  const content = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmitTopup)}>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[14px]">
              Amount
            </label>
            <Input
              name="amount"
              defaultValue={getValues("amount")}
              type="number"
              placeholder="Enter Amount to top up"
              size={"large"}
              className={` text-[14px] ${errors.amount ? "error" : ""}`}
              status={errors.amount ? "error" : ""}
              {...register("amount", {
                required: "Amount is required",
              })}
              onChange={(e) => {
                setValue("amount", e.target.value);
                trigger("amount");
              }}
            />
            <p style={{ fontSize: "13px" }} className="text-red-500">
              {errors.amount && errors.amount.message}
            </p>
          </div>
          <div className="mt-3 mb-2">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={mutateTopUpWallet.isPending}
            >
              Top-up
            </CustomButton>
          </div>
        </form>
      </>
    );
  };
  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  //================= ends here ===================================

  const handleOpenDrawer = () => {
    openDrawer("VIEW_PLAN", { company }, "BUSINESS_DETAIL");
    updateDrawerTitle("View Plan");
  };

  return (
    <>
      <div className="">
        <div className="flex justify-between items-center mb-3">
          <h4 className="font-[600] text-[18px] mb-2">{company?.name}</h4>
          {isAdmin && (
            <CustomButton size="sm" className={""} onClick={handleOpenDrawer}>
              View Plan
            </CustomButton>
          )}
        </div>
        <div className="flex gap-x-10 justify-between flex-wrap items-center">
          <div>
            <p className="font-[700] text-[15px]">
              Number of team members:{" "}
              <span className="font-[300] flex gap-x-2 items-center">
                {company?.number_of_admin}{" "}
                <small className="cursor-pointer text-[#733534] text-[10px]">
                  View team
                </small>
              </span>
            </p>
          </div>
          <div>
            <p
              className="font-[700] text-[14px] text-[#733534] cursor-pointer"
              onClick={viewTimeline}
            >
              View Timeline
            </p>
          </div>
          <div>
            {/* <p
              className="font-[700] text-[14px] text-[#733534] cursor-pointer"
              onClick={setUsage}
            >
              Set Usage
            </p> */}
          </div>
        </div>
        <p className="flex items-center gap-x-5 text-sm mt-3">
          Filter By:
          <Select
            size="small"
            defaultValue="all_time"
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "all_time", label: "All time" },
              { value: "today", label: "Today" },
              { value: "week", label: "This week" },
              { value: "month", label: "This month" },
              { value: "year", label: "This year" },
            ]}
          />
        </p>
        <div className="flex flex-col gap-y-5">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mx-2 mt-4">
            {statisticsItems?.map((item, index) => (
              <div className="" key={item?.name + "___" + index}>
                <div className="h-32 flex flex-col justify-between p-5 rounded bg-zinc-50 shadow">
                  <div className="flex justify-between">
                    <p className="font-[500] text-[14px]">{item?.name}</p>
                    {item?.name === "Wallet" && (
                      <Popover
                        content={content}
                        title="Top-up wallet"
                        trigger="click"
                        open={openPopover}
                        onOpenChange={handleOpenChange}
                      >
                        <Button
                          type="text"
                          className="font-[700] text-[12px] text-[#733534]"
                        >
                          Top-up
                        </Button>
                      </Popover>
                    )}
                  </div>
                  <p className="font-[700] text-[17px] text-[#282828]">
                    {item?.value}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="flex justify-between mb-3 items-center">
              <div>
                <Search
                  placeholder="Search reference"
                  style={{
                    width: 200,
                  }}
                />
              </div>
              <div className="flex gap-x-2">
                <Select
                  size="small"
                  defaultValue="last 7 days"
                  style={{
                    width: 120,
                  }}
                  options={[
                    {
                      value: "last 14 days",
                      label: "last 14 days",
                    },
                    {
                      value: "last 21 days",
                      label: "last 21 days",
                    },
                    {
                      value: "last 28 days",
                      label: "last 28 days",
                    },
                  ]}
                />
                <Select
                  size="small"
                  defaultValue="Feb 14, 2024 Feb 24, 2024"
                  style={{
                    width: 120,
                  }}
                  options={[
                    {
                      value: "Feb 14, 2024 Feb 24, 2024",
                      label: "Feb 14, 2024 Feb 24, 2024",
                    },
                    {
                      value: "Feb 14, 2024 Feb 24, 2024",
                      label: "Feb 14, 2024 Feb 24, 2024",
                    },
                    {
                      value: "Feb 14, 2024 Feb 24, 2024",
                      label: "Feb 14, 2024 Feb 24, 2024",
                    },
                  ]}
                />
              </div>
            </div>
            {isLoading ? (
              <div className="flex justify-center">
                <Spin />
              </div>
            ) : isError ? (
              <Result
                status="error"
                title="Unexpected error occurred"
                extra={null}
              />
            ) : (
              <BusinessUserTable rows={customers} handleOpen={handleOpen} />
            )}
          </div>
        </div>
      </div>

      {contextHolder}
    </>
  );
};

export default BusinessDetailView;
