import React from "react";
import { Checkbox } from "../../../common/form_inputs/Checkbox";
import { useStaffPosition } from "../../../../hooks/useStaffPositions";
import { useUpdatePermission } from "../../../../api/admin_mutation";
import { Result } from "antd";
import { useUserPermission } from "../../../../hooks/useUserPermission";
import { Spin } from "../../../common/form_inputs/Spin";
import { decryptData } from "../../../../utils/cryptData";

const restructureData = (data) => {
  return data?.map((item) => {
    const {
      id,
      companyId,
      name,
      createdAt,
      updatedAt,
      status,
      ...permissions
    } = item;

    // Convert permissions into an array of key-value pairs
    const permissionsArray = Object.entries(permissions).map(
      ([key, value]) => ({
        key,
        value,
        label: key.replace(/_/g, " "),
      })
    );

    return {
      id,
      companyId,
      name,
      createdAt,
      updatedAt,
      status,
      permissions: permissionsArray,
    };
  });
};

export const PermissionView = () => {
  const { data, isLoading, isError } = useStaffPosition();

  const {
    user_permission,
    isLoading: permissionLoading,
    isError: permissionError,
  } = useUserPermission();

  const permissions = restructureData(data);

  const mutation = useUpdatePermission();

  const onChange = (e, staffTypeId) => {
    const json = {
      key: e.target.name,
      staffTypeId: staffTypeId,
      status: e.target.checked,
    };

    mutation.mutate(json);
  };



  return (
    <>
      <main className="mb-5">
        <div className="grid grid-cols-2 gap-x-8 gap-y-5">
          {isLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : isError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : permissionLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : permissionError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) 
          : user_permission ? (
            user_permission?.view_settings ? (
              permissions?.map((item, index) => (
                <div
                  className="border rounded-[6px] border-[#E6E8F0] p-5"
                  key={index + "____permissions_data"}
                >
                  <p className="font-[400] text-[18px] my-3">{item?.name}</p>
                  <div className="mt-3 grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-3">
                    {item?.permissions?.map((permission, index) => (
                      <Checkbox
                        onChange={(e) => onChange(e, item?.id)}
                        disabled={!user_permission?.edit_settings}
                        defaultChecked={permission?.value}
                        // checked={permission?.value}
                        name={permission?.key}
                        key={index + "____sales_data"}
                        className={"capitalize"}
                      >
                        {decryptData(permission?.label)}
                      </Checkbox>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <>
                <i className="text-center text-lg col-span-2">
                  You are not permitted to see the settings
                </i>
              </>
            )
          ): (
            <Result
            status="error"
            title="Unexpected error occurred"
            extra={null}
          />
          )
         }
        </div>
      </main>
    </>
  );
};
