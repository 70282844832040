import React from "react";
import HeaderContent from "../../../components/specific/admin_side_loan/sidebar/HeaderContent";
import { useProfileData } from "../../../hooks/useProfileData";

const WalletAndSubscription = () => {

    const { data } = useProfileData();

    console.log(data);
  return (
    <>
      <div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mx-2 mt-4">
        <div className="h-32 flex flex-col justify-between p-5 rounded-lg bg-zinc-200 shadow-md">
            <div className="flex justify-between">
              <p className="font-[600] text-[17px] text-[#282828]">Wallet</p>
            </div>
            <p className="font-[500] text-[14px]">NGN {data?.company?.wallet_balance}</p>
          </div>
          <div className="h-32 flex flex-col justify-between p-5 rounded-lg bg-zinc-200 shadow-md">
            <div className="flex justify-between">
              <p className="font-[600] text-[17px] text-[#282828]">Subscription Plan</p>
            </div>
            <p className="font-[500] text-[14px]">{data?.company?.subscriptions?.[0]?.plan?.name}</p>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default WalletAndSubscription;
