import { create } from "zustand";

export const useModal = create((set)=>{
    return {
        isOpen: false,
        title: "",
        data: {},

        //================methods==========================
        openModal: ({title, data})=>set((state)=>({isOpen: true, title, data: {...state.data, ...data}})),
        closeModal: ()=>set({isOpen: false}),
        updateTitle: (title)=>set({title}),
        //======================...other methods as needed===========================
        updateData: (data)=>set((state)=>({data: {...state.data, ...data}}))
    }
})
