import React from "react";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";
import CustomButton from "../../../../../common/CustomButton";

const summaryData = [
  { value: "interest", label: "Interest" },
  { value: "request_guarantor", label: "Request Guarantor" },
  { value: "debit_guarantor", label: "Debit Guarantor in case of default" },
];

const ApplicationSummary = () => {
  const {
    openDrawer,
    data: { values },
  } = useDrawer();



  const handleOnComplete = () => {
    openDrawer("CREATE_SUCCESSFULL", { values: { ...values }, response_message: "Loan application link created" });
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto flex items-center justify-center mt-4">
        <div className="flex flex-col gap-y-10 w-full">
          <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm flex flex-col gap-y-8">
            <h4 className="font-[600] text-[18px] mb-2">Summary</h4>
            <div className="flex flex-col gap-y-3">
              {summaryData?.map((item, index) => (
                <div key={item?.value + index} className="flex justify-between">
                  <h3 className="font-[400] text-[14px]">{item?.label}:</h3>
                  <p className="font-[275] text-[14px]">
                    {typeof values?.[item?.value] === "boolean"
                      ? values?.[item?.value]
                        ? "Yes"
                        : "No"
                      : item?.value === "full_name"
                      ? `${values["first_name"]} ${values["last_name"]}`
                      : values?.[item?.value]}
                    {item?.value === "interest" && "%"}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                onClick={handleOnComplete}
                // disabled={} //disable when loading
              >
                Complete
              </CustomButton>
            </div>
        </div>
      </main>
    </>
  );
};

export default ApplicationSummary;