import React, { forwardRef } from 'react'
import { Select as AntSelect, ConfigProvider } from "antd"

export const Select = forwardRef(({className, ...rest}, ref) => {
  return (
    <>
    <ConfigProvider theme={{
            components: {
                Select: {
                    hoverBorderColor: "#733534",
                    activeBorderColor: "#733534",
                    colorPrimary: "#733534",
                    colorPrimaryHover: "#733534",
                    activeShadow: "0 0 0 2px #733534",
                    
                }
            }
        }}>

         <AntSelect
         ref={ref}
               {...rest}
                className={`text-[14px] h-[42px] ${className}`}
              />

        </ConfigProvider>
    </>
  )
})
