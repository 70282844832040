import { Modal } from "antd";

export default function ModalBox({
  open,
  handleClose,
  children,
  anchor,
  size,
  title,
  ...rest
}) {
  return (
    <>
      <Modal
        title={<h3 className="font-[600] text-[16px] text-center">{title}</h3>}
        // centered
        open={open}
        onCancel={handleClose}
        {...rest}
      >
        {children}
      </Modal>
    </>
  );
}
