import { Result } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { exportExcel } from "../../../utils/exportExcel";
import moment from "moment";
import { useTransactions } from "../../../api/admin_mutation";
import Pagination from "../../../components/common/form_inputs/Pagination";
import { Spin } from "../../../components/common/form_inputs/Spin";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { Select } from "../../../components/common/form_inputs/Select";
import { Input } from "../../../components/common/form_inputs/Input";
import LoanApplicationTable from "../../../components/common/table/loan_application/LoanApplicationTable";

const LoanApplication = () => {

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [start_date, ] = useState(moment().startOf("today").format("YYYY/MM/DD"))
  const [end_date, ] = useState(moment().endOf("today").format("YYYY/MM/DD"))
  const [page, setPage] = useState(1)

    const [currentData, setCurrentData] = useState([]);

    const { openDrawer, updateDrawerTitle } = useDrawer()

  const {
    user_permission,
    isLoading: permissionLoading,
    isError: permissionError,
  } = useUserPermission();


  const { mutate, data, isPending: isLoading, isError } = useTransactions();

  useEffect(() => {
    mutate({
      page,
      start_date,
      end_date,
    });
  }, [end_date, mutate, page, start_date]);


  const response = data?.data

    useEffect(() => {
    if (!isLoading && response?.data) {
      setCurrentData(response?.data);
    }
  }, [isLoading, response]);

  const tableData = useMemo(() => {
    return data?.data?.data;
  }, [data]);

  // Set the locale to start the week on Monday
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    const startDate = moment().startOf(value).format("YYYY/MM/DD");
    const endDate = moment().endOf(value).format("YYYY/MM/DD");

    mutate({
      start_date: startDate,
      end_date: endDate,
    });
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    if (value === "direct_debit") {
      setFilteredData(
        tableData?.filter((item) => item?.payment_type === "direct_debit")
      );
    } else if (value === "alternative_payment") {
      setFilteredData(
        tableData?.filter((item) => item?.payment_type !== "direct_debit")
      );
    }
    else{
      setFilteredData(
        tableData
      );
    }
    
  };



  useEffect(() => {
    const filterData = () => {
      if (searchValue?.trim()) {
        const value = searchValue?.toLowerCase();
        const updatedData = tableData?.filter((item) => {
          const matches = [item?.reference?.toLowerCase()].some((field) =>
            field?.includes(value)
          );
          return matches;
        });

        setFilteredData(updatedData?.length ? updatedData : []);
      } else {
        // If search is cleared, show full data
        setFilteredData(tableData);
      }
    };

    filterData();
  }, [searchValue, tableData]);

  const tableRows =
    searchValue?.length || selectedFilter?.length ? filteredData : currentData;


  const exportAs = (value) => {
    exportExcel({
      excelData: tableData,
      fileName: `transactions`,
    });
  };

  const handlePaginateChange = (page) => {
    setPage(page)
    mutate({
      page,
      start_date,
      end_date,
    });
  };

  const handleOpenDrawer = (data) => {
    openDrawer("KYC_PROCESS", { user_data: data });
    updateDrawerTitle("KYC Process");
  };

  return (
    <>
    <div className="h-32 flex items-center justify-center">
      <h3 className="text-lg text-gray-600"><i>Coming Soon!</i></h3>
    </div>
      {
      //   (
      // <main className="mt-3">
      //   <h3 className="font-[500] text-[18px] my-2 text-black">Loan Application</h3>
      //   <div className="flex justify-end mb-3">
      //     <div className="flex gap-x-2">
      //       <Select
      //         size="small"
      //         defaultValue="all_time"
      //         style={{ width: 120 }}
      //         onChange={handleChange}
      //         options={[
      //           { value: "all_time", label: "All time" },
      //           { value: "today", label: "Today" },
      //           { value: "week", label: "This week" },
      //           { value: "month", label: "This month" },
      //           { value: "year", label: "This year" },
      //         ]}
      //       />
      //     </div>
      //   </div>
      //   <div className="flex gap-x-3 mt-6 mb-3 justify-between">
      //     <Input
      //       placeholder="Search reference"
      //       style={{
      //         width: 250,
      //       }}
      //       onChange={(e) => setSearchValue(e.target.value)}
      //       prefix={
      //         <IoSearchOutline
      //           size={"16px"}
      //           style={{
      //             color: "rgba(0,0,0,.25)",
      //           }}
      //         />
      //       }
      //     />
      //     <div className="flex gap-x-2">
      //       <Select
      //         size="large"
      //         placeholder="Filter"
      //         style={{
      //           width: 120,
      //         }}
      //         onChange={handleFilterChange}
      //         options={[
      //           {
      //             label: "All",
      //             value: "all",
      //           },
      //           {
      //             label: "Direct debit",
      //             value: "direct_debit",
      //           },
      //           {
      //             value: "alternative_payment",
      //             label: "Alternative payment",
      //           },
      //         ]}
      //       />
      //       <Select
      //         size="large"
      //         placeholder="Export as"
      //         style={{
      //           width: 120,
      //         }}
      //         onChange={exportAs}
      //         options={[
      //           {
      //             value: "excel",
      //             label: "Export as excel",
      //           },
      //         ]}
      //       />
      //     </div>
      //   </div>

      //   {
      //  isLoading && !tableRows?.length ? (
      //   <div className="flex justify-center">
      //     <Spin />
      //   </div>
      // ): 
      //   isError ? (
      //     <Result
      //       status="error"
      //       title="Unexpected error occurred"
      //       extra={null}
      //     />
      //   ) : permissionLoading ? (
      //     <div className="flex justify-center">
      //       <Spin />
      //     </div>
      //   ) : permissionError ? (
      //     <Result
      //       status="error"
      //       title="Unexpected error occurred"
      //       extra={null}
      //     />
      //   ) : user_permission?.view_transactions ? (
      //     <div className="">
                
      //           <LoanApplicationTable rows={tableRows} handleOpen={handleOpenDrawer} />
      //           {isLoading && (
      //             <div className="flex justify-center">
      //               <Spin />
      //             </div>
      //           )}
      //           {/* <div className="mt-2">
      //             <Pagination
      //               current={response?.currentPage}
      //               align={"end"}
      //               total={response?.totalRecords}
      //               onChange={handlePaginateChange}
      //               pageSize={20}
                  
      //             />
      //           </div> */}
      //         </div>
      //   ) : (
      //     <div className="text-center my-10">
      //       <i className="text-lg">
      //         You are not permitted to view transactions
      //       </i>
      //     </div>
      //   )}
      // </main>

      //   )
      }
    </>
  );
};


export default LoanApplication