import React, { useState } from "react";
import { Input } from "../../../common/form_inputs/Input";
import { useForm, Controller } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";

const SetUsage = () => {
  const [formType, setFormType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const formFieldData = [
    {
      type: "number",
      name: "number_of_mandate",
      placeholder: "Enter maximum number of mandate",
      label: "Number of Mandate",
      headerText: "Individual mandate Cap",
    },
    {
      type: "number",
      name: "business_number_of_mandate",
      placeholder: "Enter maximum number of mandate",
      label: "Number of Mandate",
      headerText: "Business mandate Cap",
    },
    {
      type: "number",
      name: "transaction_charge",
      placeholder: "Enter percentage %",
      label: "Transaction fee in percentage",
      headerText: "Transaction charge",
      extraField: {
        name: "transaction_fee_cap",
        placeholder: "Enter transaction fee cap",
        label: "Transaction fee cap",
      },
    },
    {
      type: "number",
      name: "debit_fee",
      placeholder: "Enter debit fee",
      label: "Debit fee per transaction",
      headerText: "Debit Fee",
    },
    {
      type: "number",
      name: "number_of_admin",
      placeholder: "Enter number of admin",
      label: "Maximum number of Admin",
      headerText: "Number of Admin Members",
    },
  ];

  const onChangeNumber = (e, fieldName) => {
    const value = e.target.value?.trim();
    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };

  const onSubmit = (data, fieldName) => {
    setFormType(fieldName);
    const relevantData = {
      [fieldName]: data[fieldName],
    };

    if (fieldName === "transaction_charge") {
      relevantData["transaction_fee_cap"] = data["transaction_fee_cap"];
    }

    console.log(relevantData);

    reset()
  };

  return (
    <div className="mt-4">
      <h4 className="font-[600] text-[18px] mb-2">FundQuest</h4>
      <h5 className="">Set Usage</h5>

      <div className="grid md:grid-cols-2 grid-cols-1 mt-3 gap-5">
        {formFieldData.map((item, index) => (
          <div className="border rounded-lg p-3" key={index + "___set_usage_form"}>
            <p className="font-[400] text-[18px] text-center text-black">
              {item?.headerText}
            </p>
            <form className="mt-1 mb-2" onSubmit={handleSubmit((data) => onSubmit(data, item.name))}>
              <div className="mb-3">
                <label htmlFor={item?.name} className="font-[400] text-[16px]">
                  {item?.label}
                </label>
                <Controller
                  name={item?.name}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={item?.type}
                      placeholder={item?.placeholder}
                      size={"large"}
                      className={`text-[14px] placeholder:text-[#033F4B]`}
                      status={errors?.[item?.name] ? "error" : ""}
                    />
                  )}
                />
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors?.[item?.name] && errors?.[item?.name].message}
                </small>
              </div>

              {item?.extraField && (
                <div className="mb-3">
                  <label htmlFor={item.extraField.name} className="font-[400] text-[14px]">
                    {item.extraField.label}
                  </label>
                  <Controller
                    name={item.extraField.name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        type={"number"}
                        placeholder={item.extraField.placeholder}
                        size={"large"}
                        className={`text-[14px] placeholder:text-[#033F4B]`}
                        status={errors?.[item.extraField.name] ? "error" : ""}
                      />
                    )}
                  />
                  <small style={{ fontSize: "13px" }} className="text-red-500">
                    {errors?.[item.extraField.name] && errors?.[item.extraField.name].message}
                  </small>
                </div>
              )}

              <div className="mt-6 flex justify-center">
                <CustomButton
                  className={"w-full"}
                  type="submit"
                  loading={isLoading && formType === item?.name}
                >
                  Save
                </CustomButton>
              </div>
            </form>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SetUsage;
