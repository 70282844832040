import { useEffect, useState } from "react";
import StaffAuthLayout from "../../../components/layouts/authLayout/StaffAuthLayout";
import CustomButton from "../../../components/common/CustomButton";
import { Input } from "../../../components/common/form_inputs/Input";
import { useForm } from "react-hook-form";
import { useForgottenPsw } from "../../../api/admin_mutation";
import { ROUTES } from "../../../libs/routes";
import { useNavigate, Link } from "react-router-dom";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";
import { Result } from "antd";

const ForgotPassword = () => {
  //external hooks
  const mutation = useForgottenPsw();

  const navigate = useNavigate();

  const { showMessage, contextHolder } = useCustomMessage();

  //

  //react hooks=======================

  const [loading, setLoading] = useState(false);

  //

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    const json = {
      email: data.email,
      password: data.password,
    };

    mutation.mutate(json);
  };

  const handleChange = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };

  //useEffect for mutation
  useEffect(() => {
    if (mutation.isPending) {
      setLoading(true);
    } else if (mutation.isSuccess) {
      setLoading(false);
      let response = mutation?.data?.data;

      console.log(response);
    } else if (mutation.isError) {
      setLoading(false);
      let message = mutation?.error?.response?.data?.message;
      let message2 = mutation?.error?.message;
      showMessage("error", message ?? message2);
    }
  }, [mutation.status]);


  const message =
    mutation?.data?.data?.message ??
    mutation?.error?.response?.data?.message ??
    mutation?.error?.message;

    console.log(mutation?.data?.data?.message)

  return (
    <>
      <StaffAuthLayout title={"Forgot Password"}>
        <main className="w-full max-w-xl mx-4">
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            {
                mutation?.isSuccess ? (
                    <Result
                    status={mutation?.isSuccess ? "success" : "error"}
                    title={
                      <p className="font-[300] text-[18px] text-white">
                        {message}
                      
                      </p>
                    }
                    extra={null}
                  />
                ): (
            <>
                <div className="grid grid-cols-1 gap-x-5 md:gap-y-3">
                <div className="mb-3">
                    <label
                    htmlFor="email"
                    className="font-[400] text-[14px] text-white"
                    >
                    Enter Account Email Address
                    </label>
                    <Input
                    name="email"
                    defaultValue={getValues("email")}
                    type="email"
                    placeholder="Enter Email"
                    size={"large"}
                    className={`text-[14px] ${errors.email ? "error" : ""}`}
                    status={errors.email ? "error" : ""}
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                        value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address",
                        },
                    })}
                    onChange={(e) => handleChange(e, "email")}
                    />
                    <p>

                    <small style={{ fontSize: "13px" }} className="text-red-500">
                    {errors.email && errors.email.message}
                    </small>

                    </p>
                </div>
                </div>

                <div className="mt-8 mb-6 flex flex-col gap-y-2 gap-x-3 max-w-lg mx-auto">
                <CustomButton
                    className={"w-full"}
                    type={"submit"}
                    loading={loading}
                >
                    Send Reset Link
                </CustomButton>
                <p className="text-white font-[500] text-center cursor-pointer text-[14px]">
                    Remebered Password?{" "}
                    <Link
                    to={ROUTES.staff_login}
                    className="text-[#733534] text-decoration-none"
                    >
                    Login
                    </Link>
                </p>
                </div>
            
            </>

                )
            }
          </form>
        </main>
      </StaffAuthLayout>

      {contextHolder}
    </>
  );
};

export default ForgotPassword;
