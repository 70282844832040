import React from 'react'
import repayment_1 from "../../../assets/repayment_1.png"
import { decryptData } from '../../../utils/cryptData'

function BorrowerLayout({customer_name, company, awaitAuthorization, children}) {
  return (
    <>
    
        <main className='grid lg:grid-cols-5 h-screen overflow-y-hidden'>
            <section className='bg-[#131842] lg:w-fit md:w-screen md:flex justify-center px-6 pt-10 col-span-2 hidden'>
              <div className='flex flex-col items-center gap-y-5'>
                <h3 className='text-white font-[700] text-center text-[50px]'>{decryptData(company?.name)}</h3>
                <div className="flex flex-col gap-y-3">
                  <p className='text-white font-[600] text-center'>Dear {decryptData(customer_name)},</p>
                  <p className='text-white font-[500] text-center pb-9 '>
                    {
                      awaitAuthorization? ("Your loan schedule has been updated. It requires your approval to be activated."): (
                        "Please authorize direct debit Mandate for seamless loan repayment."
                      )
                    }
                 
                  </p>
                </div>
                <div className='lg:block md:hidden'>
                  <img src={repayment_1} alt="loan repayment" className='w-[200px] h-[200px]'/>
                </div>
              </div>
            </section>
            <section className='col-span-3 overflow-y-scroll'>
                {children}
            </section>
        </main>
    </>
  )
}

export default BorrowerLayout