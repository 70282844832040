import React from "react";
import { Image } from "antd";
import avatar from "../../../../assets/avatar.png";
import CustomButton from "../../../common/CustomButton";
import RenderSection from "../../admin_side_loan/loan/loanApplication/kyc_process/RenderSection";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";


const personal_information_fields = [
  { value: "name", label: "Name" },
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "gender", label: "Gender" },
  { value: "marital_status", label: "Marital Status" },
  { value: "dob", label: "Date of Birth" },
  { value: "nin", label: "NIN" },
];
const address_information_fields = [
  { value: "home_address", label: "Home Address" },
  { value: "city", label: "City" },
  { value: "state", label: "State" },
];
const work_fields = [
  { value: "occupation", label: "Occupation" },
  { value: "work_email", label: "Work Email" },
  { value: "salary", label: "Salary" },
  { value: "employer", label: "Employer" },
  { value: "employer_address", label: "Employer's Address" },
];






const KycProcess = () => {

  const {
    data: { form_values },
    updateCurrentPage
  } = useCustomerLoanApplication();


    const handleContinue = () => {
      updateCurrentPage("KYC_COMPLETED", null)
    }


    console.log(form_values)


  return (
    <>
      <main>
        <h4 className="font-[600] text-[18px] mb-5 text-center">Summary</h4>

        <div className="flex justify-center mb-8">
          <Image
            preview={false}
            className="rounded-lg border border-[#908A8A]"
            width={200}
            src={avatar}
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <RenderSection
            title={"Personal Information"}
            field_names={personal_information_fields}
            data={form_values}
          />
          <RenderSection
            title={"Address Information"}
            field_names={address_information_fields}
            data={form_values}
          />
          <RenderSection title={"Work Information"} field_names={work_fields}
          data={form_values}
           />
          
        </div>

        <div className="flex justify-center gap-x-8 mt-10">
          <CustomButton className="w-full md:w-3/5" onClick={handleContinue}>Continue</CustomButton>
        </div>
      </main>
    </>
  );
};

export default KycProcess;
