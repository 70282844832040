


import React, { useState } from 'react';
import { Pagination, Tag } from 'antd';
import moment from "moment";
import { IoEyeOutline } from "react-icons/io5";
import { formatNumberOnly } from '../../../../utils/formatNumber';
import { Table } from '../../../common/form_inputs/Table';
import { decryptData } from '../../../../utils/cryptData';



const EmandateHistoryTable = ({rows, handleOpen}) => {



  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));







  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, row) => (
        <div className="flex flex-col">
          <p className="font-bold my-auto">{`${decryptData(row?.first_name)} ${decryptData(row?.last_name)}`}</p>
          <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(row?.createdAt).format('h:mm:ss, D MMMM YYYY')}
          </small>
        </div>
      ),
      
    },
    {
      title: 'Mandate Ref',
      dataIndex: 'reference',
      render: (text, row)=>(
        <>
          {decryptData(row?.order_id)}
        </>
      )
    },
    {
      title: 'Total Repayable amount',
      dataIndex: 'totalRepayment',
      render: (text, row)=>(
        <>
          NGN {formatNumberOnly(parseFloat(decryptData(row?.total_amount)) - parseFloat(decryptData(row?.total_paid)))}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text)=>(
        <Tag color={text==='inactive'?"orange": text==='reschedule pending'? 'purple' : 'success'} className='px-3 py-1 capitalize border-0'>{decryptData(text)}</Tag>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' onClick={()=>handleOpen(row)}>
            <IoEyeOutline size={"18px"}/>
  
          </small>
        </>
      )
    },
  ];





  return (
    <>
        <Table
        onRow={(row)=>(
          {
            onClick:()=>handleOpen(row)
          }
        )}
        columns={columns} dataSource={dataSource} pagination={false} striped={true}
        />
    </>
  );
};
export default EmandateHistoryTable;