import KycStepper from "./kyc_process/KycStepper";
import PersonalInformation from "./kyc_process/PersonalInformation";
import AddressInformation from "./kyc_process/AddressInformation";
import WorkInformation from "./kyc_process/WorkInformation";
import SelfieIdentity from "./kyc_process/SelfieIdentity";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";

const CompleteKyc = () => {
  const {
    kyc: { currentStep },
  } = useCustomerLoanApplication();

  return (
    <>
      <main className="mt-5 flex md:gap-x-5 gap-x-4">
        <KycStepper currentStep={currentStep} />
        <div className="w-full max-w-lg my-5">
          {currentStep === 0 ? (
            <SelfieIdentity />
          ) : currentStep === 1 ? (
            <PersonalInformation />
          ) : currentStep === 2 ? (
            <AddressInformation />
          ) : currentStep === 3 ? (
            <WorkInformation />
          ) : null}
        </div>
      </main>
    </>
  );
};

export default CompleteKyc;
