
import { useEffect, useState } from "react";
import { useSendSchedule } from "../../../../api/mutation";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import CustomButton from "../../../common/CustomButton";
import { useForm } from "react-hook-form";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import loan_schedule_example from "../../../../assets/loan_schedule_example.csv"

export default function LoanMandateInformation() {
  const {
    openDrawer,
    data: { values },
  } = useDrawer();

  const { contextHolder, showMessage } = useCustomMessage();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      sc_file: null,
    },
  });

  const [fileSelected, setFileSelected] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const mutation = useSendSchedule();

  const onSubmit = (data) => {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }


    formData?.append("file", data["sc_file"])


    formData?.append("preview", "true");


    setIsLoading(true);
    mutation.mutate(formData, {
      onSuccess: (res) => {
        console.log(res?.data?.data);
        openDrawer(
          "MANDATE_SUMMARY",
          { formData, mandate: res?.data?.data },
          "MANDATE_INFO"
        );
        reset();
      },
    });
  };

  useEffect(() => {
    if (mutation.isPending) {
      setIsLoading(true);
    } else if (mutation.isError) {
      setIsLoading(false);
      const error =
        mutation?.error?.response?.data?.message ?? mutation?.message;

      showMessage("error", error);
    } else if (mutation.isSuccess) {
      setIsLoading(false);
    }
  }, [mutation?.data?.data?.data, mutation?.error?.response?.data?.message, mutation.isError, mutation.isPending, mutation.isSuccess, mutation?.message, mutation.status, showMessage]);


  const onChangeNumber = (e, fieldName) => {
    if (fieldName === "sc_file") {
      // formik.setFieldValue("sc_file", e.target.files[0])
      const value = e.target.files[0];

      console.log(value)

      setFileSelected(value);

      setValue(fieldName, value, { shouldValidate: true });
    } else {
      const value = e.target.value;

      setValue(fieldName, value);
      // formik.setFieldValue(e.target.name, e.target.value)
    }
    trigger(fieldName); // Trigger validation after setting value
  };

  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg border border-[#D9D9D996] bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Loan Information</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
           
            <div className="mb-2 gap-x-2 items-center">
              <label htmlFor="" className="font-[400] text-[16px]">
                Loan Schedule
              </label>
              <div className="flex gap-x-4 justify-between border rounded-[8px] items-center  ps-3">
                <span className="font-[400] text-[14px] text-[#033F4B]">
                  {fileSelected?.name ??
                    "Upload loan schedule here in csv format"}
                </span>

                <label
                  htmlFor="schedule_file"
                  className=" rounded-s-[0.8rem] border px-8 py-3 "
                >
                  Select file
              <input
                type="file"
                name="sc_file"
                id="schedule_file"
                onChange={(e) => onChangeNumber(e, "sc_file")}
                className="hidden"
                accept=".csv,text/csv"
              />
                </label>
              </div>
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.sc_file && errors.sc_file.message}
              </small>
              <p className="text-[14px] font-[400] text-[#033F4B]">
                <a href={loan_schedule_example} download={'loan_schedule_example.csv'} className="font-[700] text-[15px] cursor-pointer">Download{" "}</a>
              
                loan schedule template{" "}
              </p>
            </div>
            <div className="mt-4 flex justify-center">
              <CustomButton
                type={"submit"}
                className="px-8 w-full"
                loading={isLoading}
                disabled={!getValues('sc_file')}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>

      {contextHolder}
    </>
  );
}
