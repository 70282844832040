import { Button } from "antd";
import { useUserLoanApproval } from "../../../hooks/zustand_hooks/useUserLoanApproval";
import { Table } from "../../../components/common/form_inputs/Table";
import {
  useAuthorizeMandate,
  useAthorizeReschedule,
} from "../../../api/mutation";
import { useState } from "react";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";
import { formatDate } from "../../../utils/formatDate";
import { decryptData } from "../../../utils/cryptData";

export default function LoanSummary({ mandate, orderID, awaitAuthorization }) {
  const authorizeMandateMutation = useAuthorizeMandate(orderID);

  const authorizeReschedule = useAthorizeReschedule(orderID);

  const [rescheduleState, setReScheduleState] = useState("");

  const { updateCurrentPage } = useUserLoanApproval();

  const { showMessage, contextHolder } = useCustomMessage();

  const [isLoading, setIsLoading] = useState(false);

  const authorizeDirectDebit = () => {
    setIsLoading(true);

      authorizeMandateMutation.mutate("", {
        onSuccess: (data) => {
          const message = data?.data?.message;
          updateCurrentPage("BVN_OTP", { mandate: mandate, message });
        },
        onError: (err) => {
          const error = err?.response?.data?.message ?? err?.message;
  
          showMessage("error", error);
        },
        onSettled: () => {
          setIsLoading(false);
        },
      });

    // updateCurrentPage("BVN_OTP", {mandate: mandate})
  };

  const authorizeUpdate = (status) => {
    //this will run function to authorize
    setReScheduleState(status);
    authorizeReschedule.mutate(
      {
        action: status,
      },
      {
        onSuccess: (data) => {
          const message = data?.data?.message;
          updateCurrentPage("RESPONSE_VIEW", { status: "success", message });
          showMessage("success", message);
        },
        onError: (err) => {
          const error = err?.response?.data?.message ?? err?.message;
          showMessage("error", error);
        },
      }
    );
    // updateCurrentPage("RESPONSE_VIEW", {status: "success"})
  };

  const columns = [
    {
      title: "Due Date",
      dataIndex: "start_date",
      render: (text) => formatDate(decryptData(text)),
    },
    {
      title: "Principal Payment",
      dataIndex: "amount",
      align: "right",
      render: (text)=> (decryptData(text))
    },
    {
      title: "Interest Payment",
      dataIndex: "interest",
      align: "right",
      render: (text)=> (decryptData(text))
    },
    {
      title: "Total Amount Due",
      dataIndex: "total_amount_due",
      align: "right",
      render: (text)=> (decryptData(text))
    },
  ];

  const dataSource = mandate?.schedules
    ?.sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date))
    ?.map((data, i) => ({ ...data, key: data?.id + i }));

  return (
    <>
      <div className="lg:w-[80%] md:w-[90%] mx-auto px-2 py-4 rounded-lg bg-white shadow-sm mt-2">
        <h2 className="font-[600] text-[22px] text-center lg:block md:hidden">
          FI Lender
        </h2>
        <h5 className="font-[400] text-[18px] text-center mb-3">
          Loan Approval
        </h5>
        <div className="flex flex-col gap-y-1">
          <p className="font-[600] text-[15px]">
            {decryptData(mandate?.first_name)} {decryptData(mandate?.last_name)}
          </p>
          <div className="flex gap-5 flex-wrap">
            <p className="font-[500] text-[14px]">
              Loan Amount:{" "}
              <span className="font-[300]">{decryptData(mandate?.total_amount)}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Tenor: <span className="font-[300]">{decryptData(mandate?.tenor)}</span>
            </p>
          </div>
          <div className="mt-3">
            <Table
              header_bg={true}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size="small"
            />
          </div>
        </div>
        <div>
          {awaitAuthorization ? (
            <p className="font-[300] text-[14px] mt-3">
              Kindly authorize <span className="font-[500]">FI lender</span> to
              set direct debit mandate on your bank account for loan repayment
            </p>
          ) : (
            <p className="font-[300] text-[14px] mt-3">
              I requested new loan and agreed to the new loan schedule above
            </p>
          )}
        </div>
        <div className="mt-5 mb-8 flex justify-center items-center">
          {awaitAuthorization ? (
            <div className="flex justify-between w-full max-w-lg">
              <Button
                size="large"
                shape={"round"}
                loading={
                  rescheduleState === "reschedule declined" &&
                  authorizeReschedule?.isPending
                }
                className="rounded-[8px] bg-red-500 text-[#FFF] text-[18px]  hover:!bg-red-500 hover:!text-[#FFF]"
                onClick={() => authorizeUpdate("reschedule declined")}
              >
                Decline
              </Button>
              <Button
                size="large"
                shape={"round"}
                loading={
                  rescheduleState === "reschedule approved" &&
                  authorizeReschedule?.isPending
                }
                className="rounded-[8px] bg-[#2A2A29] text-[#FFF] text-[18px]  hover:!bg-[#2A2A29] hover:!text-[#FFF]"
                onClick={() => authorizeUpdate("reschedule approved")}
              >
                Approve
              </Button>
            </div>
          ) : (
            <Button
              size="large"
              shape={"round"}
              loading={isLoading}
              className="rounded-[8px] bg-[#2A2A29] text-[#FFF] text-[18px]  hover:!bg-[#2A2A29] hover:!text-[#FFF]"
              onClick={authorizeDirectDebit}
            >
              Authorize Direct debit
            </Button>
          )}
        </div>
      </div>

      {contextHolder}
    </>
  );
}
