import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import DebitAccount from "../../specific/admin_side_loan/loan/DebitAccount";
import LoanDetail from "../../specific/admin_side_loan/loan/LoanDetail";
import ResponseResultView from "../ResponseResultView";
import LoanRepaymentSchedule from "../../specific/admin_side_loan/loan/mandate_status/LoanRepaymentSchedule";
import SingleLoanDetail from "../../specific/admin_side_loan/loan/SingleLoanDetail";
import SuccessfullDebit from "../../specific/admin_side_loan/loan/SuccessfullDebit";
import DrawerBox from "../DrawerBox";
import { AddAccount } from "../../specific/settings/accounts/AddAccount";
import { InviteMember } from "../../specific/settings/teams/InviteMember";
import MandateType from "../../specific/admin_side_loan/mandateForm/MandateType";
import BusinessInformation from "../../specific/admin_side_loan/mandateForm/BusinessInformation";
import NewMandate from "../../specific/admin_side_loan/mandateForm/NewMandate";
import LoanMandateInformation from "../../specific/admin_side_loan/mandateForm/LoanMandateInformation";
import MandateCreatedSuccessfull from "../../specific/admin_side_loan/mandateForm/MandateCreateSuccessfull";
import MandateSummary from "../../specific/admin_side_loan/mandateForm/MandateSummary";
import PenalChargeView from "../../specific/admin_side_loan/mandateForm/PenalChargeView";
import { Button } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import BusinessDetailView from "../../specific/default_admin/user_details_view/BusinessDetailView";
import SetUsage from "../../specific/default_admin/set_usage/SetUsage";
import CompanyTimeline from "../../specific/default_admin/company_timeline/CompanyTimeline";
import OnboardCompany from "../../specific/default_admin/onboard_company/OnboardCompany";
import CreatePlan from "../../specific/default_admin/create_plan/CreatePlan";
import ViewPlans from "../../specific/default_admin/view_plans/ViewPlans";
import TransactionDetail from "../../specific/transaction/TransactionDetail";
import LoanApplicationType from "../../specific/admin_side_loan/loan/loanApplication/LoanApplicationType";
import CustomerInformation from "../../specific/admin_side_loan/loan/loanApplication/custom_forms/CustomerInformation";
import CreditSetting from "../../specific/admin_side_loan/loan/loanApplication/custom_forms/CreditSetting";
import CreditSummary from "../../specific/admin_side_loan/loan/loanApplication/custom_forms/CreditSummary";
import ApplicationLabel from "../../specific/admin_side_loan/loan/loanApplication/general_forms/ApplicationLabel";
import CreditRequirementForm from "../../specific/admin_side_loan/loan/loanApplication/general_forms/CreditRequirementForm";
import ApplicationSummary from "../../specific/admin_side_loan/loan/loanApplication/general_forms/ApplicationSummary";
import KycProcess from "../../specific/admin_side_loan/loan/loanApplication/kyc_process/KycProcess";
import KycConfirmation from "../../specific/admin_side_loan/loan/loanApplication/kyc_process/KycConfirmation";

export default function GeneralDrawer() {


  const size55 = ['LOAN_DETAIL', 'CUSTOMER_SCHEDULE']

  const size60 = ['BUSINESS_DETAIL', 'VIEW_PLAN']

  const size35 = ['CREATE_PLAN', 'ONBOARD_COMPANY', 'LOAN_APPLICATION_TYPE', 'CUSTOMER_INFORMATION', 'CREDIT_SETTING', 'APPLICATION_LABEL_FORM', "CREDIT_REQUIREMENT_FORM"]



  const {
    isOpen,
    closeDrawer,
    pageTitle,
    data,
    drawerTitle,
    openDrawer,
    backTo,
  } = useDrawer();




  const handleBack = () => {
    openDrawer(backTo);
  };

  return (
    <>
      <DrawerBox
        open={isOpen}
        handleClose={closeDrawer}
        size={size60.includes(pageTitle)? '60rem' : size55.includes(pageTitle)? "55rem" : size35.includes(pageTitle)? "35rem": '50rem'}
        title={<p className="text-center">{drawerTitle}</p>}
      >
        {backTo && (
          <div className="mb-2">
            <Button
              type="text"
              onClick={handleBack}
              icon={<IoArrowBackOutline />}
            >
              Back
            </Button>
          </div>
        )}

        {pageTitle === "LOAN_DETAIL" || pageTitle === "CUSTOMER_SCHEDULE" ? (
          <LoanDetail />
        ) : pageTitle === "SINGLE_LOAN_DETAIL" ? (
          <SingleLoanDetail />
        ) : pageTitle === "DEBIT_ACCOUNT" ? (
          <DebitAccount />
        ) : pageTitle === "MANDATE_ACTIVATION_RESPONSE" ? (
          <ResponseResultView />
        ) : pageTitle === "REPAYMENT_SCHEDULE" ? (
          <LoanRepaymentSchedule />
        ) : pageTitle === "LOAN_DETAIL" ? (
          <LoanDetail />
        ) : pageTitle === "SINGLE_LOAN_DETAIL" ? (
          <SingleLoanDetail />
        ) : pageTitle === "DEBIT_ACCOUNT" ? (
          <DebitAccount />
        ) : pageTitle === "ADD_ACCOUNT" ? (
          <AddAccount />
        ) : pageTitle === "INVITE_MEMBER" ? (
          <InviteMember />
        ) : pageTitle === "MANDATE_TYPE" ? (
          <MandateType />
        ) : pageTitle === "MANDATE_USER_FORM" ? (
          data?.mandateType === "individual" ? (
            <NewMandate />
          ) : (
            <BusinessInformation />
          )
        ) : pageTitle === "DEBIT_RESULT" ? (
          <SuccessfullDebit />
        ) : pageTitle === "MANDATE_INFO" ? (
          <LoanMandateInformation />
        ) : pageTitle === "MANDATE_SUMMARY" ? (
          <MandateSummary />
        ) : pageTitle === "SET_PENAL_CHARGE" ? (
          <PenalChargeView />
        ) : pageTitle === "BUSINESS_DETAIL" ? (
          <BusinessDetailView />
        ) : pageTitle === "SET_USAGE" ? (
          <SetUsage />
        ) : pageTitle === "COMPANY_TIMELINE" ? (
          <CompanyTimeline />
        ) : pageTitle === "CREATE_SUCCESSFULL" ? (
          <MandateCreatedSuccessfull />
        ) : pageTitle === "ONBOARD_COMPANY" ? (
          <OnboardCompany />
        ) : pageTitle === "CREATE_PLAN" ? (
          <CreatePlan />
        ) : pageTitle === "VIEW_PLAN" ? (
          <ViewPlans />
        ) : pageTitle === "TRANSACTION_DETAIL" ? (
          <TransactionDetail />
        ) : pageTitle === "LOAN_APPLICATION_TYPE" ? (
          <LoanApplicationType />
        ) : pageTitle === "CUSTOMER_INFORMATION" ? (
          <CustomerInformation />
        ) : pageTitle === "CREDIT_SETTING" ? (
          <CreditSetting />
        ) : pageTitle === "CREDIT_SUMMARY" ? (
          <CreditSummary />
        ) : pageTitle === "APPLICATION_LABEL_FORM" ? (
          <ApplicationLabel />
        ) : pageTitle === "CREDIT_REQUIREMENT_FORM" ? (
          <CreditRequirementForm />
        ) : pageTitle === "APPLICATION_SUMMARY" ? (
          <ApplicationSummary />
        ) : pageTitle === "KYC_PROCESS" ? (
          <KycProcess />
        ) : pageTitle === "KYC_CONFIRMATION" ? (
          <KycConfirmation />
        ) : null}
      </DrawerBox>
    </>
  );
}
