import React from "react";
import { PersonalLoanIcon, ResponsibilityIcon } from "../../../../utils/svg_icons";
import CustomButton from "../../../common/CustomButton";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useModal } from "../../../../hooks/zustand_hooks/useModal";

const WhatToDo = () => {

    const { openDrawer, updateDrawerTitle } = useDrawer();

    const { closeModal } = useModal();

    const handleAction = (action) => {
        if(action==='set_mandate'){
            openDrawer("MANDATE_TYPE");
            updateDrawerTitle("New Mandate");
        }
        else if(action === 'loan_application'){
            openDrawer("LOAN_APPLICATION_TYPE");
            updateDrawerTitle("Share Credit Application");
        }



        closeModal();
    }


  return (
    <>
      <main className="w-full max-w-lg flex justify-center items-center">
        <div className="flex flex-wrap gap-x-10 gap-y-4">
          <div className="flex flex-col items-center gap-y-2">
            <ResponsibilityIcon />
            <CustomButton size={'default'} onClick={()=>handleAction('set_mandate')}>Set Mandate</CustomButton>
          </div>
          <div className="flex flex-col items-center gap-y-2">
            <PersonalLoanIcon />
            <CustomButton size={'default'} onClick={()=>handleAction('loan_application')}>Share Loan Application</CustomButton>
          </div>
        </div>
      </main>
    </>
  );
};

export default WhatToDo;
