
import { Table } from "../../form_inputs/Table";
import moment from "moment";
import { IoEyeOutline } from "react-icons/io5";
import { decryptData } from "../../../../utils/cryptData";



const UserTable = ({rows, handleOpen}) => {


  const columns = [
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      render: (text, row) => (
          <p className="">{moment(decryptData(text)).format("DD. MMM. YYYY")}</p>
       
      ),
    },
    {
      title: 'Business Name',
      dataIndex: 'name',
      render: (text, row) => (
          <p className="">{decryptData(text)}</p>
    
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, row)=>(
        <>
          {decryptData(text)}
        </>
      )
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      render: (text, row)=>(
        <>
          {decryptData(text)}
        </>
      )
    },
    {
      title: 'view',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' onClick={()=>handleOpen(row)}>
            <IoEyeOutline size={"18px"}/>
  
          </small>
        </>
      )
    },
  ];


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}))?.reverse();





  return (
    <>
        <Table
        onRow={(row)=>(
          {
            onClick:()=>handleOpen(row)
          }
        )}
        columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} pagination={false} />
    
    </>
  );
};
export default UserTable;