import React from "react";
import RenderSection from "./RenderSection";
import { Image } from "antd";
import avatar from "../../../../../../assets/avatar.png";
import CustomButton from "../../../../../common/CustomButton";
import { useDrawer } from "../../../../../../hooks/zustand_hooks/useDrawer";

const personal_information_fields = [
  { value: "name", label: "Name" },
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "marital_status", label: "Marital Status" },
  { value: "dob", label: "Date of Birth" },
];
const address_information_fields = [
  { value: "home_address", label: "Home Address" },
  { value: "city", label: "City" },
  { value: "state", label: "State" },
];
const work_fields = [
  { value: "occupation", label: "Occupation" },
  { value: "workEmail", label: "Work Email" },
  { value: "salary", label: "Salary" },
  { value: "employer", label: "Employer" },
  { value: "employer_address", label: "Employer's Address" },
];
const identity_fields = [
  {
    value: "bvn",
    label: "BVN",
    action: (param) => console.log(param),
    action_name: "Check Authenticy",
  },
  {
    value: "nin",
    label: "NIN",
    action: (param) => console.log(param),
    action_name: "Check Authenticy",
  },
];
const credit_history_fields = [
  { value: "check_credit_bureau", label: "Check Credit bureau", link: true },
];
const bank_statement_fields = [
  { value: "bank_statment", label: "Request Bank Statement", link: true },
];






const KycProcess = () => {

    const { openDrawer } = useDrawer();

    const handleClick = () => {

        openDrawer("KYC_CONFIRMATION", null, "KYC_PROCESS");
    
    }

    


  return (
    <>
      <main>
        <h4 className="font-[600] text-[18px] mb-5 text-center">Summary</h4>

        <div className="flex justify-center mb-8">
          <Image
            preview={false}
            className="rounded-lg border border-[#908A8A]"
            width={200}
            src={avatar}
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <RenderSection
            title={"Personal Information"}
            field_names={personal_information_fields}
          />
          <RenderSection
            title={"Address Information"}
            field_names={address_information_fields}
          />
          <RenderSection title={"Work Information"} field_names={work_fields} />
          <RenderSection
            title={"Identity"}
            field_names={identity_fields}
            coming_soon={true}
            require_verify={true}
          />
          <RenderSection
            title={"Credit History"}
            field_names={credit_history_fields}
            coming_soon={true}
            require_verify={true}
          />
          <RenderSection
            title={"Bank Statement"}
            field_names={bank_statement_fields}
            coming_soon={true}
            require_verify={true}
          />
        </div>

        <div className="flex justify-center gap-x-8 mt-10">
          <CustomButton className="w-full md:w-3/5" onClick={handleClick}>Send bank mandate request</CustomButton>
        </div>
      </main>
    </>
  );
};

export default KycProcess;
