
import CustomButton from "../../../common/CustomButton";
const ShareBankStatement=()=>{
    return(
        <>
            <main className="flex flex-col gap-y-10">
            <h3 className="font-[500] text-[16px] text-center text-[#323C4D]">Share Bank Statement</h3>
            <p className="text-[22px] font-[300] leading-[33px]">Kindly continue with Mono to share your bank statement with FI Lender.</p>
            <div className="mb-5 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                // loading={isLoading}
              >
                Share bank statement with Mono
              </CustomButton>
            </div>
            </main>
        </>
    )
}


export default ShareBankStatement;