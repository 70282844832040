import React from 'react'
import { Input as AntInput, ConfigProvider } from "antd"


export const OtpInput = ({...rest}) => {
  return (
    <>
    <ConfigProvider theme={{
            components: {
                Input: {
                    hoverBorderColor: "#733534",
                    activeBorderColor: "#733534",
                    activeShadow: "0 0 0 0px #733534",
                }
            }
        }}>

         <AntInput.OTP

               {...rest}
              />

        </ConfigProvider>
    </>
  )
}
